<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account-plus-outline</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("firstcall-add-override-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-card-text>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-autocomplete
                        clearable
                        dense
                        v-model="selectedUser"
                        :items="users"
                        :loading="isLoadingUsers"
                        :search-input.sync="searchUserInput"
                        prepend-icon="mdi-account"
                        cache-items
                        outlined
                        item-text="name"
                        item-value="id"
                        :label="$t('firstcall-add-override-operator-hint')"
                        return-object
                        hide-no-data
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required|min_value:1"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        dense
                        outlined
                        :error-messages="errors"
                        :success="valid"
                        v-model="user.amount"
                        prepend-icon="mdi-account-multiple-plus"
                        :label="$t('firstcall-add-override-amount')"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-dialog
                      ref="dialog_override_date"
                      v-model="override_date_modal"
                      :return-value.sync="user.override_date"
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          dense
                          outlined
                          v-model="override_date_formatted"
                          prepend-icon="mdi-calendar"
                          :label="$t('firstcall-add-override-date-hint')"
                          readonly
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @input="$refs.dialog_override_date.save(override_date)"
                        v-model="override_date"
                        scrollable
                      >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      v-on:click="confirm()"
                      color="cyan"
                      :disabled="invalid || !validated"
                      >{{ $t("gbl-ok") }}</v-btn
                    >

                    <v-btn width="120" v-on:click="cancel()" color="gray">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import firstCall from "../../apis/firstcall";
import config from "../../config";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      selectedUser: null,
      user: {
        id: null,
        override_date: new Date().toISOString().substr(0, 10),
        amount: 1,
      },
      users: [],
      override_date: null,
      override_date_modal: false,
      isLoadingUsers: false,
      searchUserInput: null,
    };
  },

  props: ["value"],

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mounted() {},

  computed: {
    override_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.override_date);
      },
      set: function(v) {
        this.override_date = v;
      },
    },
  },

  watch: {
    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUsers = true;
          this.fetchUsers(val)
            .then((res) => {
              this.users = res.items;
              this.isLoadingUsers = false;
            })
            .catch(() => {
              this.isLoadingUsers = false;
            });
        }, config.searchInputsTimeout);
      }
    },
  },

  methods: {
    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        firstCall
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: function() {
      if (!this.override_date) this.user.override_date = null;
      this.user.id = this.selectedUser ? this.selectedUser.id : null;
      this.$emit("overrideConfigured", this.user);
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    setItem(item) {
      this.user = item;

      if (isNaN(Date.parse(this.user.override_date))) {
        this.override_date = this.$options.filters.toDatePickerDate(
          new Date(Date.now()).toString()
        );
      } else {
        this.override_date = this.$options.filters.toDatePickerDate(
          this.user.override_date
        );
      }
    },
  },
};
</script>
