var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('messageBox',{ref:"msgBox"}),_c('notificationEditor',{ref:"notificationEditor",on:{"notificationConfigured":_vm.handleNotification},model:{value:(_vm.notificationEditorDialog),callback:function ($$v) {_vm.notificationEditorDialog=$$v},expression:"notificationEditorDialog"}}),_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('div',{staticStyle:{"height":"10px"}}),(_vm.$store.state.hasRight(50))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addNotification()}}},[_vm._v(_vm._s(_vm.$t('notmng-add')))]):_vm._e(),_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"orange","icon":"mdi-bell","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(_vm._s(_vm.$t('notmng-title')))])]},proxy:true}])},[_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"options":_vm.options,"server-items-length":_vm.totalNotifications,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.hasRight(51))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","variant":"light"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('notmng-modify')))])]):_vm._e(),(_vm.$store.state.hasRight(52))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('notmng-remove')))])]):_vm._e()]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date ? _vm.$options.filters.toLocaleDate(item.start_date) : _vm.$t('notmng-type-immediate'))+" ")]}},{key:"item.expire_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expire_date ? _vm.$options.filters.toLocaleDate(item.expire_date) : _vm.$t('notmng-type-persist'))+" ")]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.notifications_levels[item.severity].name)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }