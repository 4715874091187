<template>
  <div>
    <v-dialog
      overlay-opacity="0.6"
      persistent
      max-width="800"
      :value="editGroupDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <v-card class="ma-0 pa-2">
              <v-toolbar color="red" dark>
                <v-app-bar-nav-icon
                  ><v-icon large>mdi-account-group</v-icon>
                </v-app-bar-nav-icon>

                <v-toolbar-title class="white--text">{{
                  $t("callrec-groupsmng-modify-title")
                }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon @click="cancel()" large>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-form
                class="mt-5"
                v-if="!fetchingFilters"
                ref="user-editor-form"
              >
                <v-select
                  v-model="editGroupItem.grp"
                  outlined
                  label="Ruolo"
                  item-text="text"
                  item-value="value"
                  :items="groupsList"
                  required
                  dense
                >
                </v-select>

                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      width="120"
                      v-on:click="confirm()"
                      color="error"
                      class="ma-2"
                      >{{ $t("msgbox-ok") }}</v-btn
                    >
                    <v-btn class="ma-2" idth="120" v-on:click="cancel()">{{
                      $t("msgbox-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-row class="text-center">
      <v-col>
        <v-progress-circular
          v-if="fetchingFilters"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="light-blue"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-card v-if="!fetchingFilters" class="ma-4">
      <v-toolbar color="red" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-account-group</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("callrec-groupsmng-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div style="height:10px" />

      <v-row class="ml-2">
        <v-col cols="4">
          <v-text-field
            dense
            outlined
            :value="filter"
            @change="(v) => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto ml-1 pl-1"
            :label="$t('gbl-search')"
            hide-details
          />
        </v-col>
      </v-row>

      <div class="vertical-spacer" />
      <v-data-table
        class="grid-table"
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="operators"
        :options.sync="options"
        :server-items-length="totalOperators"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
      >
        <template v-slot:item.grp="{ item }">
          {{ getGroup(item) }}
          <v-tooltip v-if="$store.state.hasRight('45')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                x-small
                text
                icon
                color="primary"
                @click.stop="editGroup(item)"
                v-on="on"
                ><v-icon>mdi-pencil</v-icon></v-btn
              ></template
            ><span>{{ $t("callrec-groupsmng-modify") }}</span></v-tooltip
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import callsManager from "../apis/recorder";
import config from "../config";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      operators: [],
      loading: false,
      totalOperators: 0,
      options: {
        sortBy: ["code"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      fetchingFilters: false,
      filter: null,

      editGroupDialog: false,
      editGroupItem: { grp: 0 },
      editGroupItemSource: null,
      groups: {},
      groupsList: [],
    };
  },

  components: {},

  mounted() {
    this.headers = [
      {
        text: this.$t("callrec-groupsmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("callrec-groupsmng-tbl-code"),
        align: "center",
        sortable: true,
        value: "code",
      },
      {
        text: this.$t("callrec-groupsmng-tbl-group"),
        align: "center",
        sortable: true,
        value: "grp",
      },
    ];

    this.fetchingFilters = true;
    this.fetchGroupsResults()
      .then((res) => {
        this.groups = {};

        for (let index in res) {
          let item = res[index];
          this.groups[item.id] = { text: item.name, value: item.id };
          this.groupsList.push({ text: item.name, value: item.id });
        }

        this.fetchingFilters = false;
      })
      .catch((err) => {
        console.log(err);
        this.fetchingFilters = false;
      });
  },

  watch: {
    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadOperators(true);
        }, config.searchInputsFastTimeout);
      },
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadOperators(false);
      },
    },
  },

  methods: {
    fetchGroupsResults() {
      return new Promise((resolve, reject) => {
        callsManager
          .fetchGroupsResults()
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getGroup(item) {
      if (item.grp) return this.groups[item.grp].text;
      else return this.$t("callrec-groupsmng-unassigned");
    },

    editGroup(item) {
      this.editGroupItem = Object.assign({}, item);
      this.editGroupItemSource = item;
      this.editGroupDialog = true;
    },

    cancel() {
      this.editGroupDialog = false;
    },

    confirm() {
      let code = this.editGroupItem.code;
      let grp = this.editGroupItem.grp;
      callsManager
        .setOpGroup({ code: code, grp: grp })
        .then(() => {
          this.editGroupItemSource.grp = grp;
          this.editGroupDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.editGroupDialog = false;
        });
    },

    reloadOperators: function(showLoader) {
      this.fetchOperators(showLoader)
        .then((data) => {
          this.operators = data.items;
          this.totalOperators = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchOperators(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        callsManager
          .fetchOperators(ctx)
          .then((result) => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    },
  },
};
</script>
