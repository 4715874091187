<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-bell</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingNotification
                      ? $t("notif-edit-modify")
                      : $t("notif-edit-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  outlined
                  v-model="notification.name"
                  :label="$t('notif-edit-hint-name')"
                  :error-messages="errors"
                  :success="valid"
                  required
                ></v-text-field>
              </ValidationProvider>

              <v-select
                hide-details
                :label="$t('notif-edit-hint-destination')"
                outlined
                dense
                v-model="notification.user_id"
                item-text="name"
                item-value="id"
                :items="users"
              >
              </v-select>
              <div class="vertical-spacer" />
              <v-select
                hide-details
                :label="$t('notif-edit-hint-severity')"
                outlined
                dense
                v-model="notification.severity"
                item-text="name"
                item-value="id"
                :items="notifications_levels"
              >
              </v-select>
              <v-row>
                <v-col>
                  <v-checkbox
                    light
                    dense
                    v-model="notification.persistent"
                    :label="$t('notif-edit-hint-persistent')"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-dialog
                    ref="notification_start_date"
                    v-model="date_start_dialog"
                    :return-value.sync="notification.start_date"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="start_date_formatted"
                        :label="$t('notif-edit-hint-startdate')"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="
                        $refs.notification_start_date.save(
                          notification.start_date
                        )
                      "
                      v-model="notification.start_date"
                      scrollable
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col>
                  <v-dialog
                    ref="notification_date"
                    v-model="date_expire_dialog"
                    :return-value.sync="notification.expire_date"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="!notification.persistent"
                        v-model="expire_date_formatted"
                        :label="$t('notif-edit-hint-expiredate')"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="
                        $refs.notification_date.save(notification.expire_date)
                      "
                      v-model="notification.expire_date"
                      scrollable
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-select
                hide-details
                :label="$t('notif-edit-hint-emitter')"
                outlined
                dense
                v-model="notification.avatar_id"
                item-text="name"
                item-value="id"
                :items="avatars"
              >
              </v-select>
              <div class="vertical-spacer" />

              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <v-textarea
                  outlined
                  v-model="notification.content"
                  :label="$t('notif-edit-hint-text')"
                  :error-messages="errors"
                  :success="valid"
                  required
                ></v-textarea>
              </ValidationProvider>

              <v-btn
                width="120"
                :disabled="invalid || !validated"
                v-on:click="confirm()"
                color="orange"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn width="120" v-on:click="cancel()" color="primary">{{
                $t("gbl-cancel")
              }}</v-btn>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

import userManager from "../../apis/users";

export default {
  data() {
    return {
      notification: {
        name: "",
        content: "",
        start_date: null,
        expire_date: null,
        persistent: false,
        severity: 1,
        user_id: null,
        avatar_id: null,
      },

      notification_level: 0,
      notifications_levels: [
        { id: 0, name: this.$t("notif-level-alert") },
        { id: 1, name: this.$t("notif-level-info") },
        { id: 2, name: this.$t("notif-level-success") },
      ],

      date_start_dialog: false,
      date_expire_dialog: false,
      users: [],
      avatars: [],
      editingNotification: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {
    userManager
      .fetchUsersNames()
      .then((users) => {
        this.users = [
          { id: null, name: this.$t("gbl-all-male") },
          ...users.items,
        ];

        this.avatars = [
          { id: null, name: this.$t("gbl-none-male") },
          ...users.items,
        ];
      })
      .catch((err) => {
        console.log(err);
      });
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.notification.start_date);
      },
    },

    expire_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.notification.expire_date
        );
      },
    },
  },

  methods: {
    confirm: function() {
      this.$emit("notificationConfigured", {
        editingNotification: this.editingNotification,
        notification: this.notification,
      });
      this.$refs.obs.reset();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    resetModal() {
      this.notification.content = "";
      this.notification.name = "";
      this.notification.start_date = null;
      this.notification.expire_date = null;
      this.notification.persistent = false;
      this.notification.severity = 1;
      this.notification.user_id = null;
    },

    setModalNotification(notification, editing) {
      this.editingNotification = editing;
      if (editing) {
        this.notification = Object.assign({}, notification);
        if (notification.start_date)
          this.notification.start_date = this.notification.start_date.substr(
            0,
            10
          );
        if (notification.expire_date)
          this.notification.expire_date = this.notification.expire_date.substr(
            0,
            10
          );
      } else {
        this.resetModal();
      }

      if (editing) {
        requestAnimationFrame(() => {
          if (this.$refs.obs) {
            this.$refs.obs.validate();
          }
        });
      } else {
        requestAnimationFrame(() => {
          if (this.$refs.obs) this.$refs.obs.reset();
        });
      }
    },
  },
};
</script>
