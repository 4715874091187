<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />
      <userEditor
        @userConfigured="handleUser"
        ref="userEditor"
        v-model="userEditorDialog"
      ></userEditor>
      <SetString ref="setPassword" />

      <v-flex md12>
        <v-btn
          v-if="$store.state.hasRight('20')"
          v-on:click="addUser()"
          color="primary"
          >{{ $t("usrmng-add") }}</v-btn
        >
        <v-btn
          v-if="$store.state.hasRight('164')"
          v-on:click="addRedactionUser()"
          color="primary"
          >{{ $t("usrmng-add-redaction") }}</v-btn
        >
        <base-material-card
          color="primary"
          icon="mdi-account"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("usrmng") }}
            </div>
          </template>

          <div class="vertical-spacer" />

          <v-row v-if="fetchingFilters" class="text-center">
            <v-col>
              <v-progress-circular
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="light-blue"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row dense v-if="!fetchingFilters">
            <v-col>
              {{ $t("usrmng-filter-states") }}
            </v-col>
          </v-row>
          <v-row dense v-if="!fetchingFilters">
            <v-col>
              <v-select
                outlined
                v-model="filters.state"
                :label="$t('usrmng-filter-states')"
                :items="flt_states"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.group"
                :label="$t('usrmng-filter-groups')"
                :items="flt_groups"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.right"
                :label="$t('usrmng-filter-rights')"
                :items="flt_rights"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="!fetchingFilters">
            <v-col>
              {{ $t("usrmng-filter-guests") }}
            </v-col>
          </v-row>
          <v-row dense v-if="!fetchingFilters">
            <v-col>
              <v-select
                outlined
                v-model="filters.guests.radio"
                :label="$t('usrmng-filter-radio')"
                :items="radios"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.guests.nation"
                :label="$t('usrmng-filter-territories')"
                :disabled="fetchingFilters"
                hide-details
                :items="flt_nations"
                dense
                item-text="name"
                item-value="tbl_postfix"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.guests.type"
                :label="$t('usrmng-filter-type')"
                :disabled="fetchingFilters"
                :items="types"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="!fetchingFilters">
            <v-col>
              {{ $t("usrmng-filter-editorial") }}
            </v-col>
          </v-row>
          <v-row dense v-if="!fetchingFilters">
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.nation"
                :label="$t('usrmng-filter-nation')"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
                :items="nations"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.region"
                :label="$t('usrmng-filter-region')"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
                :items="regions"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.type"
                :label="$t('usrmng-filter-type')"
                :disabled="fetchingFilters"
                :items="types"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="!fetchingFilters">
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.radio"
                :label="$t('usrmng-filter-radio')"
                :disabled="fetchingFilters"
                :items="radios"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.program"
                :label="$t('usrmng-filter-program')"
                :disabled="fetchingFilters"
                :items="programs"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.territories"
                :label="$t('usrmng-filter-territories')"
                :disabled="fetchingFilters"
                :items="territories"
                hide-details
                dense
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="!fetchingFilters">
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.categories"
                :label="$t('usrmng-filter-categories')"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
                :items="categories"
                multiple
                clearable
              />
            </v-col>
            <v-col>
              <v-select
                outlined
                v-model="filters.editorial.lists"
                :label="$t('usrmng-filter-lists')"
                :disabled="fetchingFilters"
                hide-details
                dense
                item-text="name"
                item-value="id"
                :items="lists"
                multiple
                clearable
              />
            </v-col>
          </v-row>

          <v-text-field
            v-if="!fetchingFilters"
            :value="filter"
            @change="v => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 500px;"
          />

          <div class="vertical-spacer" v-if="!fetchingFilters" />
          <v-data-table
            v-if="!fetchingFilters"
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="users"
            :options.sync="options"
            :server-items-length="totalUsers"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.first_name="{ item }">
              <v-avatar
                size="36px"
                v-if="checkGravatar(item) && getGravatar(item)"
              >
                <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
              </v-avatar>

              <v-avatar
                v-else-if="getProfileLetters(item)"
                color="blue"
                size="36px"
              >
                <span class="profile-text">{{ getProfileLetters(item) }}</span>
              </v-avatar>

              {{ item.first_name }}
            </template>
            <template v-slot:item.rights="{ item }">
              {{ getRight(item) }}
            </template>
            <template v-slot:item.activity="{ item }">
              <v-icon
                v-if="item.activity && item.activity_time >= 1800"
                color="red"
                >mdi-circle</v-icon
              >
              <v-icon
                v-if="item.activity && item.activity_time <= 900"
                color="green"
                >mdi-circle</v-icon
              >
              <v-icon
                v-if="
                  item.activity &&
                    item.activity_time > 900 &&
                    item.activity_time < 1800
                "
                color="orange"
                >mdi-circle</v-icon
              >
              {{ item.activity | toLocaleDateTime }}
            </template>
            <template v-slot:item.groups="{ item }">
              <span v-html="formatGroups(item)"></span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="$store.state.hasRight('159')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="item.id == $store.state.user.id"
                    x-small
                    text
                    icon
                    color="orange"
                    @click.stop="resetPassword(item)"
                    v-on="on"
                    ><v-icon>mdi-account-key</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-change-user-password") }}</span></v-tooltip
              >

              <v-tooltip v-if="$store.state.hasRight('21')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !item.can_be_edited || item.id == $store.state.user.id
                    "
                    x-small
                    text
                    icon
                    color="primary"
                    @click.stop="editItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account-edit</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-modify-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="!item.banned && $store.state.hasRight('22')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !item.can_be_edited || item.id == $store.state.user.id
                    "
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="banItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account-off</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-ban-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="item.banned && $store.state.hasRight('22')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !item.can_be_edited || item.id == $store.state.user.id
                    "
                    x-small
                    text
                    icon
                    color="success"
                    @click.stop="banItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-unban-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="
                  item.can_be_deleted &&
                    item.id != $store.state.user.id &&
                    $store.state.hasRight('23')
                "
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="deleteItem(item, true)"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-remove-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="
                  item.can_be_deleted &&
                    item.id != $store.state.user.id &&
                    $store.state.hasRight('23')
                "
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="green"
                    @click.stop="deleteItem(item, false)"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-remove-user-no-mail") }}</span></v-tooltip
              >

              <v-tooltip
                v-if="
                  (!item.can_be_deleted || item.id == $store.state.user.id) &&
                    $store.state.hasRight('23')
                "
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="true"
                    x-small
                    text
                    icon
                    color="error"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-remove-user") }}</span></v-tooltip
              >
              <v-tooltip v-if="$store.state.hasRight('114')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="purple"
                    v-on="on"
                    @click.stop="impersonate(item)"
                    ><v-icon>mdi-ghost</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-impersonate") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.banned="{ item }">
              {{ item.banned ? $t("gbl-no") : $t("gbl-yes") }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import userManager from "../apis/users";
import staticData from "../apis/static";
import rightsServices from "../services/rights";

import editorialManager from "../apis/editorial";
import messageBox from "../components/MessageBox.vue";
import userEditor from "../components/backend/UserEditor.vue";
import AvatarServices from "../services/avatars";
import SetString from "../components/SetString.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      flt_states: [],
      flt_groups: [{ id: null, name: this.$t("gbl-all-male") }],
      flt_rights: [{ id: null, name: this.$t("gbl-all-male") }],
      users: [],
      groups: {},
      loading: false,
      totalUsers: 0,
      options: {
        sortBy: ["ope_code"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",

      userEditorDialog: false,

      fetchingFilters: true,

      filters: {
        guests: {
          radio: null,
          nation: null,
          type: null
        },

        editorial: {
          nation: 86,
          region: null,
          type: null,
          radio: null,
          program: null,
          territories: null,
          categories: null,
          lists: null
        },

        state: null,
        group: null,
        right: null
      },

      radios: [],
      programs: [],
      types: [],
      territories: [],
      regions: [],
      nations: [],
      categories: [],
      lists: [],
      flt_nations: []
    };
  },

  components: {
    userEditor,
    messageBox,
    SetString
  },

  mounted() {
    this.fetchingFilters = true;

    this.flt_states = [
      { id: null, name: this.$t("gbl-all-male") },
      { id: true, name: this.$t("usrmng-filter-state-active") },
      { id: false, name: this.$t("usrmng-filter-state-disabled") }
    ];

    this.radios = [{ id: null, name: this.$t("gbl-none-female") }];
    if (this.$store.state.radios)
      this.radios = [...this.radios, ...this.$store.state.radios];

    this.programs = [{ id: null, name: this.$t("gbl-none-male") }];
    if (this.$store.state.programs)
      this.programs = [...this.programs, ...this.$store.state.programs];

    this.types = [{ id: null, name: this.$t("gbl-none-female") }];
    if (this.$store.state.types)
      this.types = [...this.types, ...this.$store.state.types];

    this.territories = [{ id: null, name: this.$t("gbl-none-male") }];

    if (this.$store.state.territories_radios)
      this.territories = [
        ...this.territories,
        ...this.$store.state.territories_radios
      ];

    this.fetchNations().then(() => {
      this.flt_nations = [
        { tbl_postfix: null, name: this.$t("gbl-none-female") },
        ...this.nations
      ];

      this.fetchRegions(this.filters.editorial.nation)
        .then(() => {
          this.fetchLists()
            .then(() => {
              this.fetchCategories(this.filters.editorial.nation)
                .then(() => {
                  this.fetchGroups()
                    .then(() => {
                      this.fetchRights()
                        .then(() => {
                          this.fetchingFilters = false;
                        })
                        .catch(err => {
                          console.log(err);
                          this.fetchingFilters = false;
                        });
                    })
                    .catch(err => {
                      console.log(err);
                      this.fetchingFilters = false;
                    });
                })
                .catch(err => {
                  console.log(err);
                  this.fetchingFilters = false;
                })
                .catch(err => {
                  console.log(err);
                  this.fetchingFilters = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.fetchingFilters = false;
            });
        })
        .catch(err => {
          console.log(err);
          this.fetchingFilters = false;
        });
    });

    this.headers = [
      {
        text: this.$t("usrmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "first_name"
      },
      {
        text: this.$t("usrmng-tbl-surname"),
        align: "center",
        sortable: true,
        value: "last_name"
      },
      {
        text: this.$t("usrmng-tbl-login"),
        align: "center",
        sortable: true,
        value: "ope_code"
      },
      {
        text: this.$t("usrmng-tbl-activity"),
        align: "center",
        sortable: true,
        value: "activity"
      },
      {
        text: this.$t("usrmng-tbl-email"),
        align: "center",
        sortable: true,
        value: "email"
      },
      {
        text: this.$t("usrmng-tbl-groups"),
        align: "center",
        sortable: true,
        value: "groups"
      },
      {
        text: this.$t("usrmng-tbl-role"),
        align: "center",
        sortable: true,
        value: "rights"
      }
    ];

    this.headers = [
      ...this.headers,
      {
        text: this.$t("usrmng-tbl-access"),
        align: "center",
        sortable: true,
        value: "banned"
      },
      { text: "", align: "center", sortable: false, value: "actions" }
    ];
  },

  watch: {
    options: {
      handler() {
        this.reloadUsers();
      },
      deep: true
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadUsers();
      }
    },

    filters: {
      handler() {
        this.currentPage = 1;
        this.reloadUsers();
      },

      deep: true
    },

    "filters.editorial.nation_id": {
      handler() {
        this.fetchRegions(this.filters.nation_id)
          .then(() => {
            this.fetchCategories(this.filters.nation_id)
              .then(() => {
                this.currentPage = 1;
                this.reloadContacts(true);
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  },

  methods: {
    fetchNations() {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.nations = [];
        this.filters.editorial.region_id = null;

        editorialManager
          .fetchNations()
          .then(nations => {
            this.fetchingFilters = false;
            this.nations = nations.map(x => {
              x.name = this.$t(x.name);
              return x;
            });

            resolve();
          })
          .catch(err => {
            this.fetchingFilters = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions(nation_id) {
      return new Promise((resolve, reject) => {
        this.fetchingFilters = true;
        this.regions = [{ id: null, name: this.$t("gbl-all-female") }];
        this.filters.region_id = null;
        if (nation_id) {
          editorialManager
            .fetchRegions(nation_id)
            .then(regions => {
              this.fetchingFilters = false;
              this.regions = [
                { id: null, name: this.$t("gbl-all-female") },
                ...regions
              ];
              resolve();
            })
            .catch(err => {
              this.fetchingFilters = false;
              console.log(err);
              reject(err);
            });
        } else {
          this.fetchingFilters = false;
          resolve();
        }
      });
    },

    fetchLists() {
      return new Promise((resolve, reject) => {
        editorialManager
          .fetchListsNames({ filters: { archived: false } })
          .then(res => {
            this.lists = [{ id: 0, name: this.$t("gbl-none-female") }, ...res];
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    fetchGroups() {
      return new Promise((resolve, reject) => {
        userManager
          .fetchGroups({})
          .then(result => {
            this.flt_groups = [
              { id: null, name: this.$t("gbl-all-male") },
              ...result.items
            ];
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },
    fetchRights() {
      return new Promise((resolve, reject) => {
        userManager
          .fetchRights({})
          .then(result => {
            this.flt_rights = [
              { id: null, name: this.$t("gbl-all-male") },
              ...result.items
            ];
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },

    fetchCategories(nation_id) {
      return new Promise((resolve, reject) => {
        if (!nation_id) nation_id = 86;
        editorialManager
          .fetchCategories(nation_id)
          .then(res => {
            this.categories = [...res];
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    resetPassword: function(item) {
      this.$refs.setPassword
        .show(
          this.$t("usrmng-resetpassword-title"),
          this.$t("usrmng-resetpassword-hint"),
          this.$t("usrmng-resetpassword-text"),
          "",
          item,
          true,
          false
        )
        .then(res => {
          let password = res.value;
          userManager
            .setNewPassword(item.id, password)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    reloadUsers: function() {
      this.users = [];
      this.totalUsers = 0;
      this.fetchUsers()
        .then(data => {
          this.users = data.items;
          this.totalUsers = data.totalCount;
        })
        .catch(err => {
          console.log(err);
        });
    },

    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    getRight(item) {
      let rights = item.rights.split(",");
      if (rightsServices.hasExactRight(rights, "sa"))
        return this.$t("usrmng-right-sa");
      else if (rightsServices.hasExactRight(rights, "a"))
        return this.$t("usrmng-right-admin");
      else if (rightsServices.hasExactRight(rights, "u"))
        return this.$t("usrmng-right-user");
      else return this.$t("usrmng-right-none");
    },

    formatGroups(item) {
      if (!item.groups) return "";
      let groups = item.groups.split(",");
      let names = [];
      for (let n in groups) {
        let id = groups[n];
        if (this.groups[id]) {
          let name = this.groups[id].name;
          names.push(name);
        }
      }

      return names.join("<BR/>");
    },

    fetchUsers() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        userManager
          .fetchUsers(ctx)
          .then(result => {
            userManager
              .fetchGroups({})
              .then(groups => {
                this.groups = {};
                for (let n in groups.items) {
                  let group = groups.items[n];
                  this.groups[group.id] = group;
                }
                this.loading = false;
                resolve(result);
              })
              .catch(err => {
                reject(err);
              });
          })
          .catch(err => {
            this.loading = false;
            reject(err);
          });
      });
    },

    handleUser(event) {
      this.userEditorDialog = false; // Close dialog

      if (event.editingUser) {
        let user = event.user;
        userManager
          .updateUser(user)
          .then(() => {
            this.reloadUsers(false);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        let user = event.user;
        userManager
          .addUser(user)
          .then(res => {
            if (!res.user && res.mailResult) {
              this.$refs.msgBox.show(
                res.mailResult,
                this.$t("msgbox-error"),
                true
              );
            } else {
              this.reloadUsers(false);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    banItem(item) {
      userManager
        .banUser(item)
        .then(() => {
          item.banned = !item.banned;
        })
        .catch(() => {});
    },

    deleteItem(item, deleteMailBox) {
      let userId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("msgbox-confirm-op"),
          this.$t("usrmng-msgbox-confirm-delete")
        )
        .then(() => {
          userManager
            .removeUser(userId, deleteMailBox)
            .then(() => {
              this.reloadUsers(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    impersonate(item) {
      let userId = item.id;
      userManager
        .impersonateUser(userId)
        .then(res => {
          this.$store
            .dispatch("impersonate", res)
            .then(() => {
              this.$router.push("/");
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    editItem(item) {
      let user = JSON.parse(JSON.stringify(item));
      this.$refs.userEditor.resetModal();
      this.$refs.userEditor.setModalUser(user, true, item);
      this.userEditorDialog = true;
    },

    addRedactionUser() {
      this.$refs.userEditor.resetModal();
      this.$refs.userEditor.setModalUser(
        {
          first_name: "",
          last_name: "",
          email: "",
          rights: "u",
          groups: "44",
          locked_locale: null,
          locked_radio: null,
          locked_type: null,
          ope_code: "ope",
          default_editorial_contacts: 40,
          target_lists: "364",
          editorial_money: 0,
          formation: 0,
          max_list_contacts: 40,
          target_list_use_master: 0,
          locked_editorial_nation: 86,
          locked_editorial_region: null,
          prefill_editorial_type: null,
          prefill_editorial_radio: null,
          prefill_editorial_program: null,
          prefill_editorial_territories: null,

          locked_redaction_creation: true
        },
        false,
        null
      );
      this.userEditorDialog = true;
    },

    addUser() {
      this.$refs.userEditor.resetModal();
      this.$refs.userEditor.setModalUser(
        {
          first_name: "",
          last_name: "",
          email: "",
          rights: "u",
          groups: "",
          locked_locale: null,
          locked_radio: null,
          locked_type: null,
          ope_code: "ope",
          default_editorial_contacts: 10,
          target_lists: null,
          max_list_contacts: 20,
          target_list_use_master: 0,
          locked_editorial_nation: 86,
          locked_editorial_region: null,
          prefill_editorial_type: null,
          prefill_editorial_radio: null,
          prefill_editorial_program: null,
          prefill_editorial_territories: null,

          locked_redaction_creation: false
        },
        false,
        null
      );
      this.userEditorDialog = true;
    }
  }
};
</script>
