<template>
    <v-container justify-center fluid grid-list-xl> 
    <messageBox ref="msgBox"/>
    <notificationEditor @notificationConfigured="handleNotification" v-model="notificationEditorDialog" ref="notificationEditor"/>
    <v-layout justify-center wrap>  
    <div style='height:10px;'></div>
        <v-btn v-if="$store.state.hasRight(50)" color='primary' v-on:click="addNotification()">{{$t('notmng-add')}}</v-btn>
        <v-flex md12>
        <base-material-card color="orange" icon="mdi-bell" inline class="px-5 py-3">
          <template v-slot:after-heading>
          <div class="display-1 font-weight-light card-header">{{$t('notmng-title')}}</div>
        </template>
        <v-divider class="mt-3" />
       <v-data-table :headers="headers" :items="notifications" :options.sync="options" :server-items-length="totalNotifications" 
           :loading="loading" hide-default-footer>
            <template v-slot:item.actions="{ item }">
            <v-tooltip  v-if="$store.state.hasRight(51)" bottom><template v-slot:activator="{ on }"><v-btn x-small text icon variant="light"  @click.stop="editItem(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{$t('notmng-modify')}}</span></v-tooltip>
            <v-tooltip  v-if="$store.state.hasRight(52)" bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="red"  @click.stop="deleteItem(item)" v-on="on"><v-icon>mdi-delete-circle-outline</v-icon></v-btn></template><span>{{$t('notmng-remove')}}</span></v-tooltip>
            </template>
            <template v-slot:item.start_date="{ item }">
                {{item.start_date ? $options.filters.toLocaleDate(item.start_date) : $t('notmng-type-immediate')}}
            </template>
             <template v-slot:item.expire_date="{ item }">
                {{item.expire_date ? $options.filters.toLocaleDate(item.expire_date) : $t('notmng-type-persist')}}
            </template>
            <template v-slot:item.severity="{ item }">
                {{notifications_levels[item.severity].name}}
            </template>
        </v-data-table>
        </base-material-card>
        </v-flex>
    </v-layout>
    </v-container>
</template>

<script>
  import staticData from '../apis/static'
  import notificationsManager from '../apis/notifications'
  
  import messageBox from '../components/MessageBox'
  import notificationEditor from '../components/backend/NotificationEditor'
  
  export default {
    data() {
      return {
        notificationEditorDialog : false,
        loading : false,

        options : {
          sortBy: ['id'],
          sortDesc: [false],
          itemsPerPage: 50,
          page: 1,
        },

        headers : [
          { text : this.$t('notmng-tbl-name') , align : 'left' , sortable : true, value: 'name'},
          { text : this.$t('notmng-tbl-startdate') , align : 'center' , sortable : true, value: 'start_date'},
          { text : this.$t('notmng-tbl-expiredate') , align : 'center' , sortable : true, value: 'expire_date'},
          { text : this.$t('notmng-tbl-severity'), align : 'center', sortable : true, value: 'severity'},
          { value: 'actions', sortable : false, text:''},
        ],

        notifications_levels : [
          { id : 0 , name : this.$t('notmng-level-alert') },
          { id : 1 , name : this.$t('notmng-level-info')},
          { id : 2 , name : this.$t('notmng-level-success') }
        ],

        notifications : [],
        totalNotifications : 0,

        currentPage: 1,
        perPage: staticData.defaultPerPage,
        totalCustomers: 0,
        sortDesc : false,
      }
    },

    components : {
      messageBox,
      notificationEditor
    },

    watch: {
      
      options: {
        handler () {
          this.fetchNotifications();
        },
        deep: true,
      },

    },

    methods: {
        fetchNotifications()  {
          this.loading = true;
          this.notifications = [];
          let ctx = Object.assign({},this.options);
          ctx.currentPage = ctx.page;
          ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
          ctx.filter = this.filter;
          ctx.sortBy = ctx.sortBy[0];
          ctx.sortDesc = ctx.sortDesc[0];
          notificationsManager.fetchNotifications(ctx).then((result) => {
            this.totalNotifications = result.count;
            this.notifications = result.notifications;
            this.loading = false;
          }).catch((err) => {
              console.log(err);
              this.loading = false;
          })

        },

        addNotification() {
          this.$refs.notificationEditor.setModalNotification({ },false);
          this.notificationEditorDialog = true;
        },

        editItem(item)  {
          let notification = JSON.parse(JSON.stringify(item));
          this.$refs.notificationEditor.setModalNotification(notification,true);
          this.notificationEditorDialog = true;
        },

       deleteItem(item)  {
          let sourceId = item.id;
          this.$refs.msgBox.show(this.$t('notmng-msgbox-delete-text'),this.$t('notmng-msgbox-delete-title')).then(() => {
            notificationsManager.removeNotification(sourceId).then(() => { 
                this.fetchNotifications();
              }).catch((err) => {
                console.log(err);
              });
          }).catch(()=> {});
      },

        handleNotification(event) {
          if (event.editingNotification) {
            let notification = event.notification;
            notificationsManager.updateNotification(notification).then(() => {
              this.fetchNotifications();
            }).catch((err) => {
              console.log(err);
            });
          } else {
            let notification = event.notification;
            notificationsManager.addNotification(notification).then(() => {
              this.fetchNotifications();
            }).catch((err) => {
              console.log(err);
            });
          }
          this.notificationEditorDialog = false;
        }


    }
  }
</script>