var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"600","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-plus-outline")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("firstcall-add-override-title")))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"clearable":"","dense":"","items":_vm.users,"loading":_vm.isLoadingUsers,"search-input":_vm.searchUserInput,"prepend-icon":"mdi-account","cache-items":"","outlined":"","item-text":"name","item-value":"id","label":_vm.$t('firstcall-add-override-operator-hint'),"return-object":"","hide-no-data":"","error-messages":errors,"success":valid,"required":""},on:{"update:searchInput":function($event){_vm.searchUserInput=$event},"update:search-input":function($event){_vm.searchUserInput=$event}},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|min_value:1","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"success":valid,"prepend-icon":"mdi-account-multiple-plus","label":_vm.$t('firstcall-add-override-amount'),"required":""},model:{value:(_vm.user.amount),callback:function ($$v) {_vm.$set(_vm.user, "amount", $$v)},expression:"user.amount"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-dialog',{ref:"dialog_override_date",attrs:{"return-value":_vm.user.override_date,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.user, "override_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.user, "override_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","outlined":"","prepend-icon":"mdi-calendar","label":_vm.$t('firstcall-add-override-date-hint'),"readonly":"","clearable":""},model:{value:(_vm.override_date_formatted),callback:function ($$v) {_vm.override_date_formatted=$$v},expression:"override_date_formatted"}},on))]}}],null,true),model:{value:(_vm.override_date_modal),callback:function ($$v) {_vm.override_date_modal=$$v},expression:"override_date_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_override_date.save(_vm.override_date)}},model:{value:(_vm.override_date),callback:function ($$v) {_vm.override_date=$$v},expression:"override_date"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"width":"120","color":"cyan","disabled":invalid || !validated},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]),_c('v-btn',{attrs:{"width":"120","color":"gray"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }