var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"600","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-bell")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editingNotification ? _vm.$t("notif-edit-modify") : _vm.$t("notif-edit-add")))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"vertical-spacer"}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('notif-edit-hint-name'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.notification.name),callback:function ($$v) {_vm.$set(_vm.notification, "name", $$v)},expression:"notification.name"}})]}}],null,true)}),_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('notif-edit-hint-destination'),"outlined":"","dense":"","item-text":"name","item-value":"id","items":_vm.users},model:{value:(_vm.notification.user_id),callback:function ($$v) {_vm.$set(_vm.notification, "user_id", $$v)},expression:"notification.user_id"}}),_c('div',{staticClass:"vertical-spacer"}),_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('notif-edit-hint-severity'),"outlined":"","dense":"","item-text":"name","item-value":"id","items":_vm.notifications_levels},model:{value:(_vm.notification.severity),callback:function ($$v) {_vm.$set(_vm.notification, "severity", $$v)},expression:"notification.severity"}}),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"light":"","dense":"","label":_vm.$t('notif-edit-hint-persistent')},model:{value:(_vm.notification.persistent),callback:function ($$v) {_vm.$set(_vm.notification, "persistent", $$v)},expression:"notification.persistent"}})],1),_c('v-col',[_c('v-dialog',{ref:"notification_start_date",attrs:{"return-value":_vm.notification.start_date,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.notification, "start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.notification, "start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('notif-edit-hint-startdate'),"readonly":""},model:{value:(_vm.start_date_formatted),callback:function ($$v) {_vm.start_date_formatted=$$v},expression:"start_date_formatted"}},on))]}}],null,true),model:{value:(_vm.date_start_dialog),callback:function ($$v) {_vm.date_start_dialog=$$v},expression:"date_start_dialog"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.notification_start_date.save(
                        _vm.notification.start_date
                      )}},model:{value:(_vm.notification.start_date),callback:function ($$v) {_vm.$set(_vm.notification, "start_date", $$v)},expression:"notification.start_date"}})],1)],1),_c('v-col',[_c('v-dialog',{ref:"notification_date",attrs:{"return-value":_vm.notification.expire_date,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.notification, "expire_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.notification, "expire_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":!_vm.notification.persistent,"label":_vm.$t('notif-edit-hint-expiredate'),"readonly":""},model:{value:(_vm.expire_date_formatted),callback:function ($$v) {_vm.expire_date_formatted=$$v},expression:"expire_date_formatted"}},on))]}}],null,true),model:{value:(_vm.date_expire_dialog),callback:function ($$v) {_vm.date_expire_dialog=$$v},expression:"date_expire_dialog"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.notification_date.save(_vm.notification.expire_date)}},model:{value:(_vm.notification.expire_date),callback:function ($$v) {_vm.$set(_vm.notification, "expire_date", $$v)},expression:"notification.expire_date"}})],1)],1)],1),_c('v-select',{attrs:{"hide-details":"","label":_vm.$t('notif-edit-hint-emitter'),"outlined":"","dense":"","item-text":"name","item-value":"id","items":_vm.avatars},model:{value:(_vm.notification.avatar_id),callback:function ($$v) {_vm.$set(_vm.notification, "avatar_id", $$v)},expression:"notification.avatar_id"}}),_c('div',{staticClass:"vertical-spacer"}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('notif-edit-hint-text'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.notification.content),callback:function ($$v) {_vm.$set(_vm.notification, "content", $$v)},expression:"notification.content"}})]}}],null,true)}),_c('v-btn',{attrs:{"width":"120","disabled":invalid || !validated,"color":"orange"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]),_c('v-btn',{attrs:{"width":"120","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }