var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"scraper-container"}},[_c('messageBox',{ref:"msgBox"}),_c('v-overlay',{attrs:{"value":_vm.commandSent}},[_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":64,"width":8,"color":"light-blue"}})],1),_c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',{attrs:{"color":"red","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-aws")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("scraper-cloud-title")))]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":false,"large":"","icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.refreshView($event)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('div',{staticStyle:{"height":"20px"}}),_c('div',{staticClass:"vertical-spacer"}),_c('v-data-table',{attrs:{"disabled":_vm.commandSent,"headers":_vm.headers,"items":_vm.instances,"loading":_vm.loading,"footer-props":_vm.dataTableFooterOptions,"item-key":"key"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInstanceName(item))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInstanceState(item))+" ")]}},(_vm.$store.state.hasRight('57'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"120px"}},[(item.State.Code != 80 && item.State.Code != 16)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":16,"width":2,"color":"light-blue"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.State.Code != 80,"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.startInstance(item)}}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("scraper-cloud-act-start")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.State.Code != 16,"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.stopInstance(item)}}},on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("scraper-cloud-act-stop")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.State.Code == 80,"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.forceStopInstance(item)}}},on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("scraper-cloud-act-forcestop")))])])],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }