<template>
  <div id="scraper-container">
    <messageBox ref="msgBox" />
    <v-overlay :value="commandSent">
      <v-progress-circular
        :indeterminate="true"
        :rotate="0"
        :size="64"
        :width="8"
        color="light-blue"
      ></v-progress-circular
    ></v-overlay>

    <v-card class="ma-4">
      <v-toolbar color="red" dark>
        <v-app-bar-nav-icon><v-icon large>mdi-aws</v-icon> </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("scraper-cloud-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer
        ><v-btn
          :disabled="false"
          large
          icon
          color="white"
          @click.stop="refreshView"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </v-toolbar>
      <div style="height:20px" />

      <div class="vertical-spacer" />

      <v-data-table
        :disabled="commandSent"
        :headers="headers"
        :items="instances"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="key"
      >
        <template v-slot:item.name="{ item }">
          {{ getInstanceName(item) }}
        </template>

        <template v-slot:item.state="{ item }">
          {{ getInstanceState(item) }}
        </template>

        <template
          v-if="$store.state.hasRight('57')"
          v-slot:item.actions="{ item }"
        >
          <div style="min-width:120px">
            <v-progress-circular
              v-if="item.State.Code != 80 && item.State.Code != 16"
              :indeterminate="true"
              :rotate="0"
              :size="16"
              :width="2"
              color="light-blue"
            ></v-progress-circular>

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.State.Code != 80"
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="startInstance(item)"
                  v-on="on"
                  ><v-icon>mdi-play</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-cloud-act-start") }}</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.State.Code != 16"
                  x-small
                  text
                  icon
                  color="orange"
                  @click.stop="stopInstance(item)"
                  v-on="on"
                  ><v-icon>mdi-stop</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-cloud-act-stop") }}</span></v-tooltip
            >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="item.State.Code == 80"
                  x-small
                  text
                  icon
                  color="red"
                  @click.stop="forceStopInstance(item)"
                  v-on="on"
                  ><v-icon>mdi-stop</v-icon></v-btn
                ></template
              ><span>{{ $t("scraper-cloud-act-forcestop") }}</span></v-tooltip
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import messageBox from "../components/MessageBox";
import staticData from "../apis/static";
import instancesManager from "../apis/scraper/instances";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      commandSent: false,
      loading: false,
      totalInstances: 0,
      instances: [],
      headers: [],
      perPage: staticData.defaultPerPage,
    };
  },

  components: {
    messageBox,
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("scraper-cloud-tbl-instance-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("scraper-cloud-tbl-ip-address"),
        align: "left",
        sortable: true,
        value: "PublicIpAddress",
      },
      {
        text: this.$t("scraper-cloud-tbl-state"),
        align: "left",
        sortable: true,
        value: "state",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];
    this.loading = true;
    instancesManager
      .fetchInstances()
      .then((result) => {
        this.instances = result.items;

        this.totalCount = result.totalCount;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
  },

  methods: {
    refreshView() {
      this.instances = [];
      this.totalCount = 0;
      this.loading = true;
      instancesManager
        .fetchInstances()
        .then((result) => {
          this.instances = result.items;

          this.totalCount = result.totalCount;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getInstanceName(item) {
      return this.getTagValue(item.Tags, "Name");
    },

    getInstanceState(item) {
      return item.State.Name;
    },

    startInstance(item) {
      this.commandSent = true;
      instancesManager
        .startInstances([item.InstanceId])
        .then(() => {
          this.commandSent = false;
          this.refreshView();
        })
        .catch((err) => {
          this.commandSent = false;
          console.log(err);
        });
    },

    stopInstance(item) {
      this.commandSent = true;
      instancesManager
        .stopInstances([item.InstanceId])
        .then(() => {
          this.commandSent = false;
          this.refreshView();
        })
        .catch((err) => {
          this.commandSent = false;
          console.log(err);
        });
    },

    forceStopInstance(item) {
      this.commandSent = true;
      instancesManager
        .forceStopInstances([item.InstanceId])
        .then(() => {
          this.commandSent = false;
          this.refreshView();
        })
        .catch((err) => {
          this.commandSent = false;
          console.log(err);
        });
    },

    getTagValue(tags, key) {
      for (let n = 0; n < tags.length; n++) {
        if (tags[n].Key == key) return tags[n].Value;
      }
      return null;
    },
  },
};
</script>
