var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('OverrideEditor',{ref:"overrideFirstCallEditorDialog",on:{"overrideConfigured":_vm.onFirstCallOverrideConfigured},model:{value:(_vm.overrideFirstCallEditorDialog),callback:function ($$v) {_vm.overrideFirstCallEditorDialog=$$v},expression:"overrideFirstCallEditorDialog"}}),_c('OverrideEditor',{ref:"overrideRecoveryEditorDialog",on:{"overrideConfigured":_vm.onRecoveryOverrideConfigured},model:{value:(_vm.overrideRecoveryEditorDialog),callback:function ($$v) {_vm.overrideRecoveryEditorDialog=$$v},expression:"overrideRecoveryEditorDialog"}}),_c('v-card',{staticClass:"ma-1"},[_c('v-toolbar',{attrs:{"color":"#0044a3","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-plus-outline")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("firstcall-overrides-title")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',[(
              _vm.$store.state.hasRight('108') && _vm.$store.state.hasRight('109')
            )?_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.removeExpiredOverrides()}}},[_vm._v(_vm._s(_vm.$t("firstcall-add-override-remove-expired")))]):_vm._e()],1)],1),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("firstcall-overrides-firstcall")))]),_c('v-spacer'),(_vm.$store.state.hasRight('108'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addFirstcallOverride()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.firstCallAssignments,"options":_vm.firstCallOptions,"server-items-length":_vm.totalFirstCallAssignments,"loading":_vm.fetchingFirstCallAssignments,"footer-props":_vm.dataTableFooterOptions,"item-key":"id"},on:{"update:options":function($event){_vm.firstCallOptions=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
            var item = ref.item;
return [(_vm.checkGravatar(item) && _vm.getGravatar(item))?_c('v-avatar',{attrs:{"size":"36px"}},[_c('v-img',{attrs:{"src":_vm.getGravatar(item),"alt":_vm.getUsername(item)}})],1):(_vm.getProfileLetters(item))?_c('v-avatar',{attrs:{"color":"green","size":"36px"}},[_c('span',{staticClass:"profile-text"},[_vm._v(_vm._s(_vm.getProfileLetters(item)))])]):_vm._e(),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.day",fn:function(ref){
            var item = ref.item;
return [(item.day)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.day)))]):_c('span',[_vm._v(_vm._s(_vm.$t("gbl-forever")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.$store.state.hasRight('108'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFistcallOverride(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("firstcall-overrides-action-remove")))])]):_vm._e()]}}])})],1)],1),_c('div',{staticStyle:{"height":"10px"}}),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("firstcall-overrides-recovery")))]),_c('v-spacer'),(_vm.$store.state.hasRight('109'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addRecoveryOverride()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.recoveryAssignments,"options":_vm.recoveryOptions,"server-items-length":_vm.totalRecoveryAssignments,"loading":_vm.fetchingRecoveryAssignments,"footer-props":_vm.dataTableFooterOptions,"item-key":"id"},on:{"update:options":function($event){_vm.recoveryOptions=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
            var item = ref.item;
return [(_vm.checkGravatar(item) && _vm.getGravatar(item))?_c('v-avatar',{attrs:{"size":"36px"}},[_c('v-img',{attrs:{"src":_vm.getGravatar(item),"alt":_vm.getUsername(item)}})],1):(_vm.getProfileLetters(item))?_c('v-avatar',{attrs:{"color":"green","size":"36px"}},[_c('span',{staticClass:"profile-text"},[_vm._v(_vm._s(_vm.getProfileLetters(item)))])]):_vm._e(),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.day",fn:function(ref){
            var item = ref.item;
return [(item.day)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.day)))]):_c('span',[_vm._v(_vm._s(_vm.$t("gbl-forever")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.$store.state.hasRight('109'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeRecoveryOverride(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("firstcall-overrides-action-remove")))])]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }