import axios from "axios";
import config from "../../config";

let instances = new Object({
  fetchInstances: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/instances/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.instances,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  startInstances: (instances) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/instances/startInstances",
        data: { instances: instances },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  stopInstances: (instances) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/instances/stopInstances",
        data: { instances: instances },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  forceStopInstances: (instances) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/instances/forceStopInstances",
        data: { instances: instances },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default instances;
