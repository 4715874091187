<template>
  <div>
    <messageBox ref="msgBox" />

    <OverrideEditor
      ref="overrideFirstCallEditorDialog"
      v-model="overrideFirstCallEditorDialog"
      @overrideConfigured="onFirstCallOverrideConfigured"
    />
    <OverrideEditor
      ref="overrideRecoveryEditorDialog"
      v-model="overrideRecoveryEditorDialog"
      @overrideConfigured="onRecoveryOverrideConfigured"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-account-plus-outline</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("firstcall-overrides-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              v-if="
                $store.state.hasRight('108') && $store.state.hasRight('109')
              "
              color="warning"
              @click="removeExpiredOverrides()"
              >{{ $t("firstcall-add-override-remove-expired") }}</v-btn
            >
          </v-col>
        </v-row>

        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("firstcall-overrides-firstcall")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$store.state.hasRight('108')"
              icon
              @click="addFirstcallOverride()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="firstCallAssignments"
              :options.sync="firstCallOptions"
              :server-items-length="totalFirstCallAssignments"
              :loading="fetchingFirstCallAssignments"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.user="{ item }">
                <v-avatar size="36px" v-if="checkGravatar(item) && getGravatar(item)">
                  <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
                </v-avatar>

                <v-avatar
                  v-else-if="getProfileLetters(item)"
                  color="green"
                  size="36px"
                >
                  <span class="profile-text">{{
                    getProfileLetters(item)
                  }}</span>
                </v-avatar>
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:item.day="{ item }">
                <span v-if="item.day"> {{ item.day | toLocaleDate }}</span>
                <span v-else>{{ $t("gbl-forever") }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="$store.state.hasRight('108')" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="red"
                      v-on="on"
                      @click.stop="removeFistcallOverride(item)"
                      ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-overrides-action-remove")
                  }}</span></v-tooltip
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <div style="height:10px;" />
        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("firstcall-overrides-recovery")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$store.state.hasRight('109')"
              @click="addRecoveryOverride()"
              icon
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="recoveryAssignments"
              :options.sync="recoveryOptions"
              :server-items-length="totalRecoveryAssignments"
              :loading="fetchingRecoveryAssignments"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.user="{ item }">
                <v-avatar size="36px" v-if="checkGravatar(item) && getGravatar(item)">
                  <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
                </v-avatar>

                <v-avatar
                  v-else-if="getProfileLetters(item)"
                  color="green"
                  size="36px"
                >
                  <span class="profile-text">{{
                    getProfileLetters(item)
                  }}</span>
                </v-avatar>
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:item.day="{ item }">
                <span v-if="item.day"> {{ item.day | toLocaleDate }}</span>
                <span v-else>{{ $t("gbl-forever") }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="$store.state.hasRight('109')" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="red"
                      v-on="on"
                      @click.stop="removeRecoveryOverride(item)"
                      ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-overrides-action-remove")
                  }}</span></v-tooltip
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import firstCall from "../apis/firstcall";
import OverrideEditor from "../components/firstcall/OverrideEditor";
import AvatarServices from "../services/avatars";
import messageBox from "../components/MessageBox.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      firstCallAssignments: [],
      recoveryAssignments: [],
      totalFirstCallAssignments: 0,
      totalRecoveryAssignments: 0,
      fetchingFirstCallAssignments: false,
      fetchingRecoveryAssignments: false,
      perPage: staticData.defaultPerPage,

      firstCallOptions: {
        sortBy: ["id"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      recoveryOptions: {
        sortBy: ["id"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],
      overrideRecoveryEditorDialog: false,
      overrideFirstCallEditorDialog: false,
    };
  },

  name: "FirstcallManage",

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("firstcall-overrides-tbl-account"),
        align: "left",
        sortable: true,
        value: "user",
      },

      {
        text: this.$t("firstcall-overrides-tbl-amount"),
        align: "center",
        sortable: true,
        value: "amount",
      },

      {
        text: this.$t("firstcall-overrides-tbl-date"),
        align: "center",
        sortable: true,
        value: "day",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  components: {
    OverrideEditor,
    messageBox,
  },

  watch: {
    firstCallOptions: {
      handler() {
        this.fetchFirstCallAssignments();
      },
      deep: true,
    },
    recoveryOptions: {
      handler() {
        this.fetchRecoveryAssignments();
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    removeFistcallOverride: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("firstcall-overrides-action-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          firstCall
            .removeFirstCallOverride(item.id)
            .then(() => {
              this.fetchFirstCallAssignments();
            })

            .catch((err) => {
              if (err) console.log(err);
            });
        })
        .catch(() => {});
    },

    removeRecoveryOverride: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("firstcall-overrides-action-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          firstCall
            .removeRecoveryOverride(item.id)
            .then(() => {
              this.fetchRecoveryAssignments();
            })

            .catch((err) => {
              if (err) console.log(err);
            });
        })
        .catch(() => {});
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    addFirstcallOverride() {
      this.$refs.overrideFirstCallEditorDialog.setItem({
        override_date: new Date().toISOString().substr(0, 10),
        amount: 1,
      });
      this.overrideFirstCallEditorDialog = true;
    },

    addRecoveryOverride() {
      this.$refs.overrideRecoveryEditorDialog.setItem({
        override_date: new Date().toISOString().substr(0, 10),
        amount: 1,
      });
      this.overrideRecoveryEditorDialog = true;
    },

    fetchFirstCallAssignments() {
      this.fetchingFirstCallAssignments = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.firstCallOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstCall
          .fetchFirstCallAssignments(ctx)
          .then((result) => {
            this.firstCallAssignments = result.items;
            this.totalFirstCallAssignments = result.totalCount;
            this.fetchingFirstCallAssignments = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingFirstCallAssignments = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    fetchRecoveryAssignments() {
      this.fetchingRecoveryAssignments = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.recoveryOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstCall
          .fetchRecoveryAssignments(ctx)
          .then((result) => {
            this.recoveryAssignments = result.items;
            this.totalRecoveryAssignments = result.totalCount;
            this.fetchingRecoveryAssignments = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingRecoveryAssignments = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    onFirstCallOverrideConfigured(item) {
      this.overrideFirstCallEditorDialog = false;
      firstCall
        .addFirstCallOverride(item)
        .then(() => {
          this.fetchFirstCallAssignments();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onRecoveryOverrideConfigured(item) {
      this.overrideRecoveryEditorDialog = false;
      firstCall
        .addRecoveryOverride(item)
        .then(() => {
          this.fetchRecoveryAssignments();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeExpiredOverrides() {
      firstCall
        .removeExpiredOverrides()
        .then(() => {
          this.fetchRecoveryAssignments();
          this.fetchFirstCallAssignments();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
