<template>
  <div>
    <messageBox ref="msgBox" />

    <v-card class="ma-4">
      <v-toolbar color="#0044a3">
        <v-icon color="white" large class="mr-2">mdi-chart-areaspline</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("firstcall-stats-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <div style="margin:15px">
        <v-row dense justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-operator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.group"
              outlined
              item-text="text"
              item-value="value"
              :items="groups"
              :label="$t('firstcall-filter-group')"
              hide-details
            />
          </v-col>

          <v-col>
            <v-select
              :label="$t('firstcall-filter-source-scheduler')"
              outlined
              dense
              v-model="filters.scheduler"
              item-text="name"
              item-value="id"
              :items="flt_schedulers"
            />
          </v-col>
          <v-col>
            <v-select
              :label="$t('firstcall-filter-source-radio')"
              outlined
              dense
              v-model="filters.radio"
              item-text="name"
              item-value="id"
              :items="flt_radios"
            />
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col>
            <v-select
              :label="$t('firstcall-filter-datetype')"
              outlined
              dense
              v-model="filters.date_type"
              item-text="name"
              item-value="id"
              :items="date_types"
            />
          </v-col>

          <v-col>
            <v-select
              class="minfield"
              outlined
              dense
              :label="$t('filters-period-hint')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
              :disabled="fetchingData || loading"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingData || loading || filters.filterByPeriod != 9
                  "
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('guests-filter-datestart')"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingData || loading || filters.filterByPeriod != 9
                  "
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('guests-filter-dateend')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <v-btn
            :disabled="fetchingData || loading"
            color="success"
            @click.stop="downloadExcelFile()"
            ><v-icon>mdi-file-excel</v-icon
            >{{ $t("firstcall-stats-export") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="green"
            icon="mdi-account-tie"
            :title="$t('firstcall-stats-firstdayguests')"
            :value="
              statistics.day_guests != null
                ? statistics.day_guests.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-postdayguests')"
            :value="
              statistics.post_guests != null
                ? statistics.post_guests.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="warning"
            icon="mdi-cart"
            :title="$t('firstcall-stats-firstdaypayments')"
            :value="
              statistics.day_paying_guests != null
                ? statistics.day_paying_guests.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="error"
            icon="mdi-currency-eur"
            :title="$t('firstcall-stats-postdaypayments')"
            :value="
              statistics.post_paying_guests != null
                ? statistics.post_paying_guests.toString()
                : '...'
            "
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="orange"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-ioyes-sum')"
            :value="computeYesOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="orange"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-iono-sum')"
            :value="computeNoOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="orange"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-iomaybe-sum')"
            :value="computeMaybeOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="orange"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-ionr-sum')"
            :value="computeNrOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="orange"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-iolisten-sum')"
            :value="computeListenOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="orange"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-iopayed-sum')"
            :value="computePayedOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="success"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-iogroup-sumyes')"
            :value="computePositiveOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <base-material-stats-card
            color="error"
            icon="mdi-account-multiple"
            :title="$t('firstcall-stats-iogroup-sumno')"
            :value="computeNegativeOutComes"
            sub-icon="mdi-calendar"
            :sub-text="$t('firstcall-resume-daterange')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-icon color="green">mdi-checkbox-blank-circle</v-icon
          >{{ $t("firstcall-stats-tbl-outcomes-etepgep-legend") }}
        </v-col>
        <v-col>
          <v-icon color="red">mdi-checkbox-blank-circle</v-icon
          >{{ $t("firstcall-stats-tbl-outcomes-otopgop-legend") }}
        </v-col>

        <v-col>
          <v-icon color="purple">mdi-checkbox-blank-circle</v-icon
          >{{ $t("firstcall-stats-tbl-outcomes-ptppgpp-legend") }}
        </v-col>

        <v-col>
          <v-icon color="cyan">mdi-checkbox-blank-circle</v-icon
          >{{ $t("firstcall-stats-tbl-outcomes-baproall-legend") }}
        </v-col>

        <v-col>
          <v-icon color="orange">mdi-checkbox-blank-circle</v-icon
          >{{ $t("firstcall-stats-tbl-outcomes-io-legend") }}
        </v-col>
        <v-col>
          <v-icon color="yellow">mdi-checkbox-blank-circle</v-icon
          >{{ $t("firstcall-stats-tbl-outcomes-conv-pot-legend") }}
        </v-col>
      </v-row>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="firstCallOperators"
        :item-class="row_classes"
        :options.sync="options"
        :server-items-length="totalFirstCallOperators"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.first_name="{ item }">
          <v-avatar size="36px" v-if="checkGravatar(item) && getGravatar(item)">
            <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
          </v-avatar>

          <v-avatar
            v-else-if="getProfileLetters(item)"
            color="blue"
            size="36px"
          >
            <span class="profile-text">{{ getProfileLetters(item) }}</span>
          </v-avatar>
          {{ item.first_name }} {{ item.last_name }}
        </template>

        <template v-slot:item.user_id="{ item }">
          {{ item.ope_code }}
        </template>

        <template v-slot:item.etepgep="{ item }">
          {{ item.total_outcomes }} / {{ item.day_outcomes }} /
          {{ item.post_outcomes }}
        </template>

        <template v-slot:item.otopgop="{ item }">
          {{ item.total_guests }} / {{ item.day_guests }} /
          {{ item.post_guests }}
        </template>

        <template v-slot:item.ptppgpp="{ item }">
          {{ item.total_paying_guests }} / {{ item.day_paying_guests }} /
          {{ item.post_paying_guests }}
        </template>

        <template v-slot:item.baproall="{ item }">
          {{ item.total_basic }} / {{ item.total_pro }} /
          {{ item.total_all_social }}
        </template>

        <template v-slot:item.conv-pot-yes="{ item }">
          {{ item.yes_outcomes }} /
          {{ item.yes_potential_conversion.toFixed(2) }} %
        </template>

        <template v-slot:item.conv-pot-no="{ item }">
          {{ item.no_outcomes }} /
          {{ item.no_potential_conversion.toFixed(2) }} %
        </template>

        <template v-slot:item.conv-pot-maybe="{ item }">
          {{ item.maybe_outcomes }} /
          {{ item.maybe_potential_conversion.toFixed(2) }} %
        </template>

        <template v-slot:item.conv-pot-nr="{ item }">
          {{ item.nr_outcomes }} /
          {{ item.nr_potential_conversion.toFixed(2) }} %
        </template>

        <template v-slot:item.conv-pot-listen="{ item }">
          {{ item.listen_outcomes }} /
          {{ item.listen_potential_conversion.toFixed(2) }} %
        </template>

        <template v-slot:item.conv-pot-payed="{ item }">
          {{ item.payed_outcomes }} /
          {{ item.payed_potential_conversion.toFixed(2) }} %
        </template>

        <template v-slot:item.conv-pot="{ item }">
          {{ item.total_conversion.toFixed(2) }} % /
          {{ item.day_conversion.toFixed(2) }} % /
          {{ item.post_conversion.toFixed(2) }} %
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import config from "../config";
import guestManager from "../apis/guests";
import firstcall from "../apis/firstcall";
import messageBox from "../components/MessageBox";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      statistics: {
        day_guests: "...",
        post_guests: "...",
        day_paying_guests: "...",
        post_paying_guests: "...",
        day_outcomes: "...",
        post_outcomes: "...",
        total_guests: "...",
        total_outcomes: "...",
        total_paying_guests: "...",
        total_working_days: "...",
      },

      date_types: [
        { id: 0, name: this.$t("firstcall-filter-date-outcome") },
        { id: 1, name: this.$t("firstcall-filter-date-interview") },
      ],

      groups: firstcall.flt_groups,

      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      searchOperatorInput: null,

      operator: null,
      firstCallOperators: [],
      operators: [],
      isLoadingOperator: false,
      loading: false,
      totalFirstCallOperators: 0,
      perPage: staticData.defaultPerPage,

      options: {
        sortBy: ["total_conversion"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],

      flt_schedulers: [],
      flt_radios: [],

      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByPeriod: 12,
        operator: null,
        group: null,
        scheduler: null,
        radio: null,
        date_type: 0
      },

      fetchingData: false,

      guestBarrier: 13,
      callBarrier: 20,
    };
  },

  components: {
    messageBox,
  },

  computed: {
    computeYesOutComes: {
      get: function() {
        if (this.statistics.yes_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (this.statistics.yes_outcomes / totals) * 100.0;
        return (
          this.statistics.yes_outcomes.toString() +
          " (" +
          perc.toFixed(2).toString() +
          " %)"
        );
      },
    },

    computeNoOutComes: {
      get: function() {
        if (this.statistics.no_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (this.statistics.no_outcomes / totals) * 100.0;
        return (
          this.statistics.no_outcomes.toString() +
          " (" +
          perc.toFixed(2).toString() +
          " %)"
        );
      },
    },

    computeNrOutComes: {
      get: function() {
        if (this.statistics.nr_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (this.statistics.nr_outcomes / totals) * 100.0;
        return (
          this.statistics.nr_outcomes.toString() +
          " (" +
          perc.toFixed(2).toString() +
          " %)"
        );
      },
    },

    computePayedOutComes: {
      get: function() {
        if (this.statistics.payed_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (this.statistics.payed_outcomes / totals) * 100.0;
        return (
          this.statistics.payed_outcomes.toString() +
          " (" +
          perc.toFixed(2).toString() +
          " %)"
        );
      },
    },

    computeListenOutComes: {
      get: function() {
        if (this.statistics.listen_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (this.statistics.listen_outcomes / totals) * 100.0;
        return (
          this.statistics.listen_outcomes.toString() +
          " (" +
          perc.toFixed(2).toString() +
          " %)"
        );
      },
    },

    computeMaybeOutComes: {
      get: function() {
        if (this.statistics.maybe_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (this.statistics.maybe_outcomes / totals) * 100.0;
        return (
          this.statistics.maybe_outcomes.toString() +
          " (" +
          perc.toFixed(2).toString() +
          " %)"
        );
      },
    },

    computePositiveOutComes: {
      get: function() {
        if (this.statistics.maybe_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;
        let sum =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes;

        let perc = (sum / totals) * 100.0;
        return sum.toString() + " (" + perc.toFixed(2).toString() + " %)";
      },
    },

    computeNegativeOutComes: {
      get: function() {
        if (this.statistics.maybe_outcomes == null) return "...";
        let totals =
          this.statistics.payed_outcomes +
          this.statistics.yes_outcomes +
          this.statistics.listen_outcomes +
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;
        let sum =
          this.statistics.no_outcomes +
          this.statistics.nr_outcomes +
          this.statistics.maybe_outcomes;

        let perc = (sum / totals) * 100.0;
        return sum.toString() + " (" + perc.toFixed(2).toString() + " %)";
      },
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  mounted() {
    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];
    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers,
    ];

    this.headers = [
      {
        text: this.$t("firstcall-stats-tbl-firstname"),
        align: "left",
        sortable: true,
        value: "first_name",
      },

      {
        text: this.$t("firstcall-stats-tbl-code"),
        align: "left",
        sortable: true,
        value: "user_id",
      },

      {
        text: this.$t("firstcall-stats-tbl-working-days"),
        align: "center",
        sortable: true,
        value: "total_working_days",
      },

      {
        text: this.$t("firstcall-stats-tbl-outcomes-etepgep"),
        align: "center",
        sortable: true,
        value: "etepgep",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-otopgop"),
        align: "center",
        sortable: true,
        value: "otopgop",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-ptppgpp"),
        align: "center",
        sortable: true,
        value: "ptppgpp",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-baproall"),
        align: "center",
        sortable: true,
        value: "baproall",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot-yes"),
        align: "center",
        sortable: true,
        value: "conv-pot-yes",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot-no"),
        align: "center",
        sortable: true,
        value: "conv-pot-no",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot-maybe"),
        align: "center",
        sortable: true,
        value: "conv-pot-maybe",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot-nr"),
        align: "center",
        sortable: true,
        value: "conv-pot-nr",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot-listen"),
        align: "center",
        sortable: true,
        value: "conv-pot-listen",
      },

      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot-payed"),
        align: "center",
        sortable: true,
        value: "conv-pot-payed",
      },
      {
        text: this.$t("firstcall-stats-tbl-outcomes-conv-pot"),
        align: "center",
        sortable: true,
        value: "conv-pot",
      },
    ];

    this.operators = [];
  },

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.new_id : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadFirstCallOperators();
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        this.firstCallOperators = [];
        this.totalFirstCallOperators = 0;
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["conversion"],
          sortDesc: [true],
        };
      },

      deep: true,
    },
  },

  methods: {
    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    row_classes(item) {
      if (this.filters.group != null) {
        let total_outcomes = item.total_outcomes / item.total_working_days;
        let day_guests = item.day_guests / item.total_working_days;
        if (this.filters.group == 0) {
          if (
            day_guests < this.guestBarrier ||
            total_outcomes < this.outcomeBarrier
          )
            return "red--text";
        } else if (this.filters.group == 1) {
          if (
            day_guests < this.recoveryGuestBarrier ||
            total_outcomes < this.recoveryOutcomeBarrier
          )
            return "red--text";
        }
      }

      if (item.conversion >= 25.0 || item.potential_conversion >= 50.0)
        return "green--text";
      return null;
    },

    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    getItemConversion(item) {
      return item.toFixed(2) + " %";
    },

    reloadFirstCallOperators: function() {
      this.firstCallOperators = [];
      this.totalFirstCallOperators = 0;
      this.statistics = {
        potential_conversion: "",
        conversion: "",
        day_conversion: "",
        post_conversion: "",
        day_guests: "",
        post_guests: "",
        day_paying_guests: "",
        post_paying_guests: "",
        day_outcomes: "",
        post_outcomes: "",
        total_guests: "",
        total_outcomes: "",
        total_paying_guests: "",
        total_working_days: "",
      };

      this.fetchFirstCallStatistics()
        .then((data) => {
          this.firstCallOperators = data.items;
          this.totalFirstCallOperators = data.totalCount;
          this.statistics = data.stats;
          this.guestBarrier = data.guestBarrier;
          this.callBarrier = data.callBarrier;
        })
        .catch((err) => {
          if (err) console.log(err);
        });
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        guestManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFirstCallStatistics() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstcall
          .fetchFirstCallStatistics(ctx)
          .then((result) => {
            this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    downloadExcelFile() {
      firstcall.downloadExcelStatistics(
        this.$store.state.user.locale,
        this.filters
      );
    },
  },
};
</script>

<style>
.rs-bold {
  font-weight: 600;
}

a {
  color: #0044a3 !Important;
}
</style>
