<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("usreditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div style="height:10px" v-if="fetchingData" />

            <v-row v-if="fetchingData">
              <v-col>
                <v-progress-circular
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="light-blue"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form class="mt-5" v-if="!fetchingData" ref="user-editor-form">
              <ValidationObserver ref="obs" v-slot="{ invalid }">
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      immediate
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="user.first_name"
                        :label="$t('usreditor-hint-name')"
                        prepend-icon="mdi-account"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      immediate
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="user.last_name"
                        :label="$t('usreditor-hint-surname')"
                        prepend-icon="mdi-account"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :immediate="true"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="user.email"
                        :label="$t('usreditor-hint-email')"
                        prepend-icon="mdi-email"
                        required
                        :error-messages="errors"
                        :success="valid"
                        readonly
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="user.ope_code"
                        :label="$t('usreditor-hint-ope-code')"
                        prepend-icon="mdi-account-badge-horizontal-outline"
                        :error-messages="errors"
                        :success="valid"
                        :readonly="editingUser"
                        required
                      ></v-text-field>

                      <span
                        class="caption"
                        v-if="!ope_code_state"
                        style="color:red"
                        >{{ $t("usreditor-hint-opecodeexists") }}</span
                      >
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-role')"
                      outlined
                      dense
                      v-model="user.role"
                      prepend-icon="mdi-account-supervisor"
                      item-text="name"
                      item-value="id"
                      :items="roles"
                    /> </v-col
                  ><v-col>
                    <v-checkbox
                      class="mt-1"
                      outlined
                      dense
                      v-model="user.formation"
                      :label="$t('usreditor-hint-formation')"
                      prepend-icon="mdi-euro"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider
                ><span class="subtitle-1 font-weight-light">{{
                  $t("usreditor-hint-guests-filters")
                }}</span
                ><v-divider class="mb-4"></v-divider>

                <v-row dense>
                  <v-col>
                    <v-select
                      :disabled="user.locked_redaction_creation"
                      hide-details
                      :label="$t('usreditor-hint-radio')"
                      outlined
                      dense
                      v-model="user.locked_radio"
                      prepend-icon="mdi-radio-tower"
                      item-text="name"
                      item-value="id"
                      :items="flt_radios"
                      multiple
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      :disabled="user.locked_redaction_creation"
                      hide-details
                      :label="$t('usreditor-hint-locale')"
                      outlined
                      dense
                      v-model="user.locked_locale"
                      prepend-icon="mdi-flag"
                      item-text="name"
                      item-value="code"
                      :items="flt_locales"
                      multiple
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      :disabled="user.locked_redaction_creation"
                      hide-details
                      :label="$t('usreditor-hint-type')"
                      outlined
                      dense
                      v-model="user.locked_type"
                      prepend-icon="mdi-radio-tower"
                      item-text="name"
                      item-value="id"
                      :items="flt_types"
                      multiple
                    />
                  </v-col>
                </v-row>

                <v-divider class="mt-4"></v-divider
                ><span class="subtitle-1 font-weight-light">{{
                  $t("usreditor-hint-editorial-filters")
                }}</span
                ><v-divider class="mb-4"></v-divider>

                <v-row dense>
                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-editorial-nation')"
                      outlined
                      dense
                      v-model="user.locked_editorial_nation"
                      prepend-icon="mdi-flag"
                      item-text="name"
                      item-value="id"
                      :items="flt_nations"
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-editorial-region')"
                      outlined
                      dense
                      v-model="user.locked_editorial_region"
                      prepend-icon="mdi-flag"
                      item-text="name"
                      item-value="id"
                      :items="flt_regions"
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-editorial-filters-type')"
                      outlined
                      dense
                      v-model="user.prefill_editorial_type"
                      prepend-icon="mdi-radio-tower"
                      item-text="name"
                      item-value="id"
                      :items="prefill_types"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-editorial-filters-radio')"
                      outlined
                      dense
                      v-model="user.prefill_editorial_radio"
                      prepend-icon="mdi-radio-tower"
                      item-text="name"
                      item-value="id"
                      :items="prefill_radios"
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-editorial-filters-program')"
                      outlined
                      dense
                      v-model="user.prefill_editorial_program"
                      prepend-icon="mdi-radio-tower"
                      item-text="name"
                      item-value="id"
                      :items="prefill_programs"
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      hide-details
                      :label="
                        $t('usreditor-hint-editorial-filters-territories')
                      "
                      outlined
                      dense
                      v-model="user.prefill_editorial_territories"
                      prepend-icon="mdi-radio-tower"
                      item-text="name"
                      item-value="id"
                      :items="prefill_territories_radios"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <v-select
                      :disabled="user.locked_redaction_creation"
                      hide-details
                      :label="
                        $t('usreditor-hint-editorial-target-list-use-master')
                      "
                      outlined
                      dense
                      v-model="user.target_list_use_master"
                      prepend-icon="mdi-account-supervisor-outline"
                      item-text="name"
                      item-value="id"
                      :items="list_master"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :disabled="user.locked_redaction_creation"
                      hide-details
                      :label="$t('usreditor-hint-editorial-categories')"
                      outlined
                      dense
                      v-model="user.target_categories"
                      prepend-icon="mdi-account-group-outline"
                      item-text="name"
                      item-value="id"
                      :items="target_categories"
                      multiple
                      clearable
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :disabled="user.locked_redaction_creation"
                      hide-details
                      :label="$t('usreditor-hint-editorial-target-list')"
                      outlined
                      dense
                      v-model="user.target_lists"
                      prepend-icon="mdi-account-group-outline"
                      item-text="name"
                      item-value="id"
                      :items="target_lists"
                      multiple
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="email"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        v-model="user.editorial_reply_to"
                        :label="$t('usreditor-hint-editorial-replyto-email')"
                        prepend-icon="mdi-email-outline"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="required|max_value:150|min_value:10"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :disabled="user.locked_redaction_creation"
                        outlined
                        dense
                        v-model="user.default_editorial_contacts"
                        :label="$t('usreditor-hint-editorial-max-contacts')"
                        prepend-icon="mdi-account-details-outline"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="4">
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="required|max_value:150|min_value:1"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :disabled="user.locked_redaction_creation"
                        outlined
                        dense
                        v-model="user.max_list_contacts"
                        :label="
                          $t('usreditor-hint-editorial-max-list-contacts')
                        "
                        prepend-icon="mdi-account-details-outline"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider> </v-col
                  ><v-col cols="4">
                    <v-checkbox
                      class="mt-1"
                      outlined
                      dense
                      v-model="user.editorial_money"
                      :label="$t('usreditor-hint-editorial-money')"
                      prepend-icon="mdi-euro"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      v-if="!editingUser"
                      rules="required"
                      v-slot="{ errors, valid }"
                      vid="password_confirm"
                    >
                      <v-text-field
                        hide-details
                        autocomplete="new-password"
                        v-model="user.password"
                        prepend-icon="mdi-lock"
                        :label="$t('usreditor-hint-password')"
                        :append-icon="password_view ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (password_view = !password_view)"
                        :type="password_view ? 'password' : 'text'"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      v-if="!editingUser"
                      rules="required|confirmed:password_confirm"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="repeat_password"
                        prepend-icon="mdi-lock"
                        :label="$t('usreditor-hint-repeatpassword')"
                        :error-messages="errors"
                        :append-icon="
                          password_confirm ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          () => (password_confirm = !password_confirm)
                        "
                        :type="password_confirm ? 'password' : 'text'"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div style="height:10px" />

                <v-row
                  v-if="
                    user &&
                      !user.locked_redaction_creation &&
                      user.role &&
                      user.role == 'u'
                  "
                >
                  <v-col>
                    <v-card>
                      <v-toolbar dense color="primary accent-4" dark>
                        <v-toolbar-title class="white--text ma-0 text-center">{{
                          $t("usreditor-groups")
                        }}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="pa-0">
                        <v-row class="pa-0">
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-item-group>
                                <v-list-item
                                  v-for="item in usersGroups"
                                  :key="item.id"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-accounts-multiple</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                  <v-btn
                                    @click="removeGroup(item)"
                                    icon
                                    dark
                                    color="error"
                                  >
                                    <v-icon>mdi-delete-circle-outline</v-icon>
                                  </v-btn>
                                </v-list-item></v-list-item-group
                              ></v-list
                            >
                          </v-col>
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-item-group>
                                <v-list-item
                                  v-for="item in availableGroups"
                                  :key="item.id"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-accounts-multiple</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                  <v-btn
                                    @click="addGroup(item)"
                                    icon
                                    dark
                                    color="green"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </v-list-item-group></v-list
                            >
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    user &&
                      !user.locked_redaction_creation &&
                      user.role &&
                      user.role == 'u'
                  "
                >
                  <v-col>
                    <v-card>
                      <v-toolbar dense color="primary accent-4" dark>
                        <v-toolbar-title class="white--text ma-0 text-center">{{
                          $t("usreditor-rights")
                        }}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="pa-0">
                        <v-row class="pa-0">
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-group
                                v-for="right in rights"
                                :key="right.item.id"
                                v-model="right.item.active"
                                no-action
                              >
                                <v-icon slot="prependIcon" color="success"
                                  >mdi-application</v-icon
                                >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      v-text="right.item.app_description"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <v-list-item
                                  v-for="child in right.childs"
                                  :key="child.id"
                                >
                                  <v-list-item-title
                                    class="text-left"
                                    v-text="child.name"
                                  ></v-list-item-title>
                                  <v-list-item-icon>
                                    <v-btn
                                      v-if="!child.inherited"
                                      @click="removeRight(child)"
                                      icon
                                      dark
                                      color="red"
                                    >
                                      <v-icon>mdi-delete-circle-outline</v-icon>
                                    </v-btn>
                                    <v-icon v-if="child.inherited"
                                      >mdi-account-group</v-icon
                                    >
                                  </v-list-item-icon>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-col>
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-group
                                v-for="right in availableRights"
                                :key="right.item.id"
                                v-model="right.item.active"
                                no-action
                              >
                                <v-icon slot="prependIcon" color="primary"
                                  >mdi-application</v-icon
                                >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      v-text="right.item.app_description"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <v-list-item
                                  v-for="child in right.childs"
                                  :key="child.id"
                                >
                                  <v-list-item-title
                                    class="text-left"
                                    v-text="child.name"
                                  ></v-list-item-title>
                                  <v-btn
                                    @click="addRight(child)"
                                    icon
                                    dark
                                    color="green"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="invalid || !ope_code_state"
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import userManager from "../../apis/users";
import editorialManager from "../../apis/editorial";
import applicationManager from "../../apis/applications";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import config from "../../config";

export default {
  data() {
    return {
      password_confirm: String,
      password_view: String,
      user: {
        role: "u"
      },
      groups: [],
      groupsById: {},
      usersGroups: [],
      flt_locales: [],
      flt_radios: [],
      flt_types: [],
      flt_nations: [],
      flt_regions: [],
      availableGroups: [],
      repeat_password: "",

      prefill_types: [],
      prefill_radios: [],
      prefill_programs: [],
      prefill_territories_radios: [],

      applications: {},
      applications_rights: {},
      availableRights: {},
      rights: {},

      roles: [],
      list_master: [],
      target_lists: [],
      target_categories: [],
      editingUser: false,
      fetchingData: false,
      ope_code_state: true,
      ope_code_check: false
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: ["value"],

  mounted() {
    this.prefill_radios = [{ id: null, name: this.$t("gbl-none-female") }];
    if (this.$store.state.radios)
      this.prefill_radios = [
        ...this.prefill_radios,
        ...this.$store.state.radios
      ];

    this.prefill_programs = [{ id: null, name: this.$t("gbl-none-male") }];
    if (this.$store.state.programs)
      this.prefill_programs = [
        ...this.prefill_programs,
        ...this.$store.state.programs
      ];

    this.prefill_types = [{ id: null, name: this.$t("gbl-none-female") }];
    if (this.$store.state.types)
      this.prefill_types = [...this.prefill_types, ...this.$store.state.types];

    this.prefill_territories_radios = [
      { id: null, name: this.$t("gbl-none-male") }
    ];
    if (this.$store.state.territories_radios)
      this.prefill_territories_radios = [
        ...this.prefill_territories_radios,
        ...this.$store.state.territories_radios
      ];

    this.list_master = [
      { id: 1, name: this.$t("gbl-yes") },
      { id: 0, name: this.$t("gbl-no") }
    ];
    this.fetchLists();
    this.fetchCategories(this.user.locked_editorial_nation);
  },

  watch: {
    "user.locked_editorial_nation": {
      handler(val) {
        this.fetchNations(val);
      }
    },

    "user.ope_code": {
      handler(val) {
        if (!this.editingUser) {
          this.ope_code_check = true;
          userManager
            .checkOpeCode(val, this.user.id)
            .then(result => {
              this.ope_code_check = false;
              if (result.data.responseData.count != null) {
                if (result.data.responseData.count == 0) {
                  this.ope_code_state = true;
                  this.user.email = val + "@" + config.mailServerDomain;
                } else {
                  this.ope_code_state = false;
                }
              } else {
                this.ope_code_state = false;
              }
            })
            .catch(err => {
              console.log(err);
              this.ope_code_state = false;
              this.ope_code_check = false;
            });
        }
      }
    }
  },

  methods: {
    fetchLists() {
      this.fetchingData = true;
      editorialManager
        .fetchListsNames({ filters: { archived: false } })
        .then(res => {
          this.target_lists = [...res];
          this.fetchingData = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingData = false;
        });
    },

    fetchCategories(nation_id) {
      if (!nation_id) nation_id = 86;
      this.fetchingData = true;
      editorialManager
        .fetchCategories(nation_id)
        .then(res => {
          this.target_categories = [...res];
          this.fetchingData = false;
        })
        .catch(err => {
          this.fetchingData = false;
          console.log(err);
        });
    },

    addGroup: function(item) {
      let items = this.user.groups
        .split(",")
        .filter(i => i)
        .map(Number);
      items.sort((a, b) => a - b);
      if (!items.includes(item.id)) items.push(item.id);
      this.user.groups = items.join(",");
      this.calculateRights();
    },

    removeGroup: function(item) {
      let items = this.user.groups
        .split(",")
        .filter(i => i != item.id)
        .map(Number);
      items.sort((a, b) => a - b);
      this.user.groups = items.join(",");
      this.calculateRights();
    },

    removeRight: function(item) {
      let items = this.user.rights.split(",").filter(i => i);
      items = items.filter(x => x != item.id);
      items.sort((a, b) => a - b);
      this.user.rights = items.join(",");
      this.calculateRights();
    },

    addRight: function(item) {
      let items = this.user.rights.split(",").filter(i => i);
      items.push(item.id);
      items.sort((a, b) => a - b);
      this.user.rights = items.join(",");
      this.calculateRights();
    },

    reloadAppicationsAndPermissions: function() {
      return new Promise((resolve, reject) => {
        this.applications = {};
        this.rights = {};
        this.fetchApplicationsAndPermissions()
          .then(data => {
            for (let index in data.items) {
              let app = data.items[index];
              if (!this.applications[app.name]) {
                this.applications[app.name] = {
                  description: app.description,
                  rights: []
                };
              }

              this.applications[app.name].rights.push({
                id: app.right_id,
                name: app.right_name
              });
              this.applications_rights[app.right_id] = {
                id: app.right_id,
                name: app.right_name,
                app_name: app.name,
                app_description: app.description
              };
            }
            this.fetchGroups()
              .then(data => {
                this.groups = data.items;
                for (let item in this.groups) {
                  let group = this.groups[item];
                  if (group) {
                    this.groupsById[group.id] = group;
                    if (!group.rights) group.rights = "";
                    group.rightsArray = group.rights.split(",").filter(i => i);
                  }
                }
                resolve();
              })
              .catch(err => {
                console.log(err);
                reject(err);
              });
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchNations() {
      return new Promise((resolve, reject) => {
        this.fetchingNations = true;
        editorialManager
          .fetchNations()
          .then(nations => {
            this.flt_nations = nations.map(x => {
              x.name = this.$t(x.name);
              return x;
            });
            this.fetchingNations = false;
            resolve();
          })
          .catch(err => {
            this.fetchingNations = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchRegions(nation_id) {
      if (!nation_id) nation_id = 86;
      return new Promise((resolve, reject) => {
        this.fetchingRegions = true;
        this.flt_regions = [];

        editorialManager
          .fetchRegions(nation_id)
          .then(regions => {
            this.fetchingRegions = false;
            this.flt_regions = [
              { id: null, name: this.$t("gbl-none-female") },
              ...regions
            ];
            resolve();
          })
          .catch(err => {
            this.fetchingRegions = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchGroups() {
      return new Promise((resolve, reject) => {
        userManager
          .fetchGroups({})
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },

    fetchApplicationsAndPermissions() {
      return new Promise((resolve, reject) => {
        applicationManager
          .fetchApplicationsAndPermissions()
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },

    buildAvailableRights() {
      this.availableRights = {};
      for (let right in this.applications_rights) {
        let app_right_obj = this.applications_rights[right];
        let right_obj = this.rights[app_right_obj.app_name];
        if (
          !right_obj ||
          !right_obj.childs.some(item => {
            return item.id == app_right_obj.id;
          })
        ) {
          if (!this.availableRights[app_right_obj.app_name])
            this.availableRights[app_right_obj.app_name] = {
              item: app_right_obj,
              childs: []
            };
          this.availableRights[app_right_obj.app_name].childs.push(
            app_right_obj
          );
        }
      }
    },

    calculateRights() {
      let user_groups = [];
      let group_rights = [];
      this.availableGroups = [];
      this.usersGroups = [];
      this.availableGroups = [];
      if (this.user.groups)
        user_groups = this.user.groups.split(",").filter(i => i);
      for (let index in user_groups) {
        let group = user_groups[index];
        let groupItem = this.groupsById[group];
        if (groupItem) {
          this.usersGroups.push(groupItem);
          group_rights = [...group_rights, ...groupItem.rightsArray];
        }
      }

      for (let n = 0; n < this.groups.length; n++) {
        let group = this.groups[n];
        if (!this.usersGroups.includes(group)) this.availableGroups.push(group);
      }

      let user_rights = [];
      this.rights = {};
      if (this.user.rights)
        user_rights = this.user.rights.split(",").filter(i => i);
      user_rights = [...user_rights, ...group_rights].filter(
        (v, i, a) => a.indexOf(v) === i
      );

      for (let index in user_rights) {
        let right = user_rights[index];
        if (this.applications_rights[right]) {
          let right_obj = this.applications_rights[right];
          if (group_rights.includes(right_obj.id.toString()))
            right_obj.inherited = true;
          else right_obj.inherited = false;

          if (!this.rights[right_obj.app_name])
            this.rights[right_obj.app_name] = { item: right_obj, childs: [] };
          this.rights[right_obj.app_name].childs.push(right_obj);
        }
      }

      this.buildAvailableRights();
    },

    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    handleSubmit() {
      this.user.locked_radio =
        this.user.locked_radio.length > 0
          ? this.user.locked_radio.join(",")
          : "";
      this.user.locked_locale =
        this.user.locked_locale.length > 0
          ? this.user.locked_locale.join(",")
          : "";
      this.user.locked_type =
        this.user.locked_type.length > 0 ? this.user.locked_type.join(",") : "";

      this.$refs.obs.reset();
      this.$emit("userConfigured", {
        editingUser: this.editingUser,
        user: this.user
      });
    },

    resetModal() {
      this.repeat_password = "";
      this.user.password = "";
    },

    setModalUser(user, editing) {
      if (this.$store.state.tr_locales)
        this.flt_locales = [...this.$store.state.tr_locales];
      else this.flt_locales = [];

      if (this.$store.state.radios)
        this.flt_radios = [...this.$store.state.radios];
      else this.flt_radios = [];

      if (this.$store.state.types)
        this.flt_types = [...this.$store.state.types];
      else this.flt_types = [];

      this.fetchingData = true;

      this.user = Object.assign({}, user);

      this.user.target_lists = this.user.target_lists
        ? this.user.target_lists.split(",").map(x => {
            return parseInt(x);
          })
        : [];
      this.user.target_categories = this.user.target_categories
        ? this.user.target_categories.split(",").map(x => {
            return parseInt(x);
          })
        : [];

      this.user.locked_radio = user.locked_radio
        ? user.locked_radio
            .toString()
            .split(",")
            .filter(x => {
              if (x && x.length > 0) return true;
              else return false;
            })
            .map(x => {
              return parseInt(x);
            })
        : [];
      this.user.locked_locale = user.locked_locale
        ? user.locked_locale
            .toString()
            .split(",")
            .filter(x => {
              if (x && x.length > 0) return true;
              else return false;
            })
            .map(x => {
              return x;
            })
        : [];
      this.user.locked_type = user.locked_type
        ? user.locked_type
            .toString()
            .split(",")
            .filter(x => {
              if (x && x.length > 0) return true;
              else return false;
            })
            .map(x => {
              return parseInt(x);
            })
        : [];

      this.editingUser = editing;
      if (!this.editingUser) this.resetModal();
      if (
        this.user.rights
          .toString()
          .split(",")
          .filter(i => i)
          .includes("a")
      )
        this.user.role = "a";
      else if (
        this.user.rights
          .toString()
          .split(",")
          .filter(i => i)
          .includes("u")
      )
        this.user.role = "u";

      this.roles = [{ id: "u", name: "Utente" }];
      if (this.$store.state.isSuperAdmin())
        this.roles.push({ id: "a", name: "Amministratore" });

      this.reloadAppicationsAndPermissions()
        .then(() => {
          this.calculateRights();
          this.fetchingData = false;
          if (editing) {
            requestAnimationFrame(() => {
              if (this.$refs.obs) {
                this.$refs.obs.validate();
              }
            });
          } else {
            requestAnimationFrame(() => {
              if (this.$refs.obs) this.$refs.obs.reset();
            });
          }
        })
        .catch(err => {
          this.fetchingData = false;
          console.log(err);
        });

      this.fetchNations().then(() => {
        this.fetchRegions(this.user.locked_editorial_nation);
      });

      /* eslint-enable no-unused-vars */
    }
  }
};
</script>
