<template>
  <div>
    <GuestEditor
      ref="guestEditor"
      v-model="guestEditorDialog"
      @guestConfigured="onGuestConfigured"
      :vcoutcomes="$store.state.vcoutcomes"
      :livesoutcomes="$store.state.livesoutcomes"
      :programs="$store.state.programs"
      :categories="$store.state.categories"
      :regions="$store.state.regions"
      :radios="$store.state.radios"
      :schedulers="$store.state.schedulers"
      :territories_radios="$store.state.territories_radios"
      :filtered_users="flt_users"
      :users="users"
      :locales="$store.state.tr_locales"
      :types="$store.state.types"
    />
    <ChooseFirstCallOperator
      @onOperatorChoosen="onOperatorChoosen"
      ref="ChooseFirstCallOperator"
    />
    <messageBox ref="msgBox" />
    <Datetime
      input-class="date-invisible"
      ref="fencingDateTime"
      v-model="fencing_date"
      type="datetime"
    ></Datetime>
    <AudioPlayer ref="audioPlayerDialog" v-model="audioPlayerDialog" />

    <OutcomesResume
      ref="outcomesResumeDialog"
      v-model="outcomesResumeDialog"
      :outcomes="outcomes"
    />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-cellphone-sound</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("firstcall-manage-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row v-if="fetchingOutcomes || fetchingData">
          <v-col>
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="light-blue"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-card v-if="!fetchingOutcomes && !fetchingData" class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("firstcall-manage-guests")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row dense justify="center">
              <v-col class="text-center">
                <v-select
                  :disabled="fetchingGuests"
                  dense
                  v-model="filters.group"
                  outlined
                  item-text="text"
                  item-value="value"
                  :items="groups"
                  :label="$t('firstcall-filter-group')"
                  hide-details
                />
              </v-col>
              <v-col>
                <v-select
                  :label="$t('firstcall-filter-source-scheduler')"
                  outlined
                  dense
                  v-model="filters.scheduler"
                  item-text="name"
                  item-value="id"
                  :items="flt_schedulers"
                  :disabled="fetchingGuests"
                />
              </v-col>
              <v-col>
                <v-select
                  :label="$t('firstcall-filter-source-radio')"
                  outlined
                  dense
                  v-model="filters.radio"
                  item-text="name"
                  item-value="id"
                  :items="flt_radios"
                  :disabled="fetchingGuests"
                />
              </v-col>
              <v-col class="text-center">
                <v-select
                  :disabled="fetchingGuests"
                  dense
                  v-model="filters.outcome"
                  outlined
                  item-text="name"
                  item-value="id"
                  :items="flt_outcomes"
                  :label="$t('firstcall-filter-outcomes')"
                  hide-details
                  
                />
              </v-col>

              <v-col>
                <v-autocomplete
                  :disabled="fetchingGuests"
                  clearable
                  dense
                  v-model="operator"
                  :items="operators"
                  :loading="isLoadingOperator"
                  :search-input.sync="searchOperatorInput"
                  cache-items
                  outlined
                  item-text="name"
                  item-value="id"
                  :label="$t('firstcall-filter-operator')"
                  return-object
                  hide-no-data
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col>
                <v-select
                  :label="$t('firstcall-filter-datetype')"
                  outlined
                  dense
                  v-model="filters.date_type"
                  item-text="name"
                  item-value="id"
                  :items="date_types"
                  :disabled="fetchingGuests"
                />
              </v-col>

              <v-col>
                <v-select
                  class="minfield"
                  outlined
                  dense
                  :label="$t('filters-period-hint')"
                  ref="period"
                  v-model="filters.filterByPeriod"
                  :items="getPeriodFilters()"
                  item-text="name"
                  item-value="id"
                  :disabled="fetchingGuests"
                >
                </v-select>
              </v-col>

              <v-col>
                <v-dialog
                  ref="dialog_start_date"
                  v-model="date_start_modal"
                  :return-value.sync="filters.filterByCustomDateStart"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      v-model="start_date_formatted"
                      :disabled="fetchingGuests || filters.filterByPeriod != 9"
                      :label="$t('filters-startdate-hint')"
                      readonly
                      clearable
                      v-on="on"
                      
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="
                      $refs.dialog_start_date.save(
                        filters.filterByCustomDateStart
                      )
                    "
                    v-model="filters.filterByCustomDateStart"
                    scrollable
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col>
                <v-dialog
                  ref="dialog_end_date"
                  v-model="date_end_modal"
                  :return-value.sync="filters.filterByCustomDateEnd"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      v-model="end_date_formatted"
                      :disabled="fetchingGuests || filters.filterByPeriod != 9"
                      :label="$t('filters-enddate-hint')"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="
                      $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                    "
                    v-model="filters.filterByCustomDateEnd"
                    scrollable
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col class="text-center">
                <v-text-field
                  :disabled="fetchingGuests"
                  :value="filter"
                  @change="(v) => (filter = v)"
                  append-icon="mdi-magnify"
                  :label="$t('gbl-search')"
                  hide-details
                  outlined
                  dense
                  clearable
                />
              </v-col>
            </v-row>
            <v-row v-if="date_fencing" dense>
              <v-col>
                <v-alert
                  type="warning"
                  color="orange darken-1"
                  class="elevation-4"
                  dark
                >
                  {{ printDateFencingWarning() }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-btn
                  v-if="$store.state.hasRight('118') && !date_fencing"
                  @click="setDataFencing()"
                  color="error"
                  >{{ $t("firstcall-datafencing-enable") }}</v-btn
                >
                <v-btn
                  v-if="$store.state.hasRight('118') && date_fencing"
                  @click="disableDateFencing()"
                  color="green"
                  >{{ $t("firstcall-datafencing-disable") }}</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" lg="4">
                <base-material-card
                  color="error"
                  icon="mdi-phone"
                  :title="$t('firstcall-manage-queue')"
                >
                  <v-card-text>
                    <v-divider />
                    <v-container>
                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-fctodaypendingcalls") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.queuedGuests
                              ? statistics.queuedGuests.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-fcpastpendingcalls") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.postDayGuests
                              ? statistics.postDayGuests.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-fcrecoverypendingcalls") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.recoveryGuests
                              ? statistics.recoveryGuests.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-fclastresort") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.lastResortGuests
                              ? statistics.lastResortGuests.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </base-material-card>
              </v-col>

              <v-col cols="12" sm="6" lg="4">
                <base-material-card
                  color="primary"
                  icon="mdi-cellphone-wireless"
                  :title="$t('firstcall-manage-calls')"
                >
                  <v-card-text>
                    <v-divider />
                    <v-container>
                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-calls-firstcall") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.inProgressCalls
                              ? statistics.inProgressCalls.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-calls-secondday") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.inProgressPostDayCalls
                              ? statistics.inProgressPostDayCalls.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-calls-recovery") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.inProgressRecoveryCalls
                              ? statistics.inProgressRecoveryCalls.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          {{ $t("firstcall-manage-calls-lastresort") }}
                        </v-col>
                        <v-col>
                          {{
                            statistics.inProgressLastResortCalls
                              ? statistics.inProgressLastResortCalls.toString()
                              : "0"
                          }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </base-material-card>
              </v-col>

              <v-col cols="12" sm="6" lg="4">
                <base-material-stats-card
                  color="warning"
                  icon="mdi-account-group"
                  :title="$t('firstcall-manage-totalguests')"
                  :value="
                    this.statistics.totalGuests
                      ? this.statistics.totalGuests.toString()
                      : '...'
                  "
                  sub-icon="mdi-calendar"
                  :sub-text="$t('firstcall-resume-daterange')"
                />

                <base-material-stats-card
                  color="error"
                  icon="mdi-currency-eur"
                  :title="$t('firstcall-manage-totalpayments')"
                  :value="
                    this.statistics.payedGuests
                      ? this.statistics.payedGuests.toString()
                      : '...'
                  "
                  sub-icon="mdi-calendar"
                  :sub-text="$t('firstcall-resume-daterange')"
                />
              </v-col>
            </v-row>

            <v-data-table
              dense
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="guests"
              :options.sync="options"
              :server-items-length="totalGuests"
              :loading="fetchingGuests"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.operator="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-icon v-if="item.operator_name" color="cyan" v-on="on"
                      >mdi-information-outline</v-icon
                    > </template
                  ><span>{{ item.operator_name }}</span></v-tooltip
                >
                {{ item.operator_code }}
              </template>

              <template v-slot:item.outcomes_count="{ item }">
                <span>{{ item.outcomes_count }}</span
                ><span>
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="item.outcomes_count < 1"
                        x-small
                        text
                        icon
                        color="cyan"
                        @click.stop="outcomesResume(item)"
                        v-on="on"
                        ><v-icon color="green"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      ></template
                    ><span>{{ $t("gbl-outcomes-resume") }}</span></v-tooltip
                  ></span
                >
              </template>

              <template v-slot:item.assignment_day="{ item }">
                {{ item.assignment_day | toLocaleDate }}
              </template>

              <template v-slot:item.time_elapsed="{ item }">
                <span style="font-weight:800">{{
                  formatElapsed(item.time_elapsed)
                }}
                  <v-tooltip
                    bottom
                    v-if="
                      (item.wa_events &&
                        item.wa_events.split(',').includes('sent')) ||
                        item.wa_date
                    "
                    ><template v-slot:activator="{ on }"
                      ><v-icon small color="green" v-on="on"
                        >mdi-check</v-icon
                      > </template
                    ><span>{{ $t("gbl-msg-state-sent") }}</span></v-tooltip
                  >

                  <v-tooltip
                    bottom
                    v-if="
                      item.wa_events &&
                        item.wa_events.split(',').includes('delivered')
                    "
                    ><template v-slot:activator="{ on }"
                      ><v-icon small color="cyan" v-on="on"
                        >mdi-check</v-icon
                      > </template
                    ><span>{{ $t("gbl-msg-state-delivered") }}</span></v-tooltip
                  >

                  <v-tooltip
                    bottom
                    v-if="
                      item.wa_events &&
                        item.wa_events.split(',').includes('read')
                    "
                    ><template v-slot:activator="{ on }"
                      ><v-icon small color="cyan" v-on="on"
                        >mdi-check</v-icon
                      > </template
                    ><span>{{ $t("gbl-msg-state-read") }}</span></v-tooltip
                  >

                  <v-tooltip
                    bottom
                    v-if="
                      item.wa_events &&
                        item.wa_events.split(',').includes('failed')
                    "
                    ><template v-slot:activator="{ on }"
                      ><v-icon small color="red" v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      > </template
                    ><span>{{ $t("gbl-msg-state-failed") }}</span></v-tooltip
                  >

                  <v-tooltip
                    bottom
                    v-if="
                      item.wa_events &&
                        item.wa_events.split(',').includes('undelivered')
                    "
                    ><template v-slot:activator="{ on }"
                      ><v-icon small color="red" v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      > </template
                    ><span>{{
                      $t("gbl-msg-state-notdelivered")
                    }}</span></v-tooltip
                  >

                  <v-tooltip bottom v-if="item.wa_events"
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        small
                        text
                        icon
                        color="primary"
                        @click.stop="downloadWAReceipt(item)"
                        v-on="on"
                        ><v-icon>mdi-download</v-icon></v-btn
                      ></template
                    ><span>{{ $t("gbl-download-wa-receipt") }}</span></v-tooltip
                  >
                </span>

              </template>

              <template v-slot:item.send_link_wa="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click.stop="sendWA(item)"
                      v-on="on"
                      ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                    ></template
                  ><span>{{ $t("firstcall-action-wa") }}</span></v-tooltip
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:120px">
                  <v-tooltip v-if="$store.state.hasRight('117')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        v-on="on"
                        color="purple"
                        @click.stop="reattachAssignment(item)"
                        ><v-icon>mdi-pipe</v-icon></v-btn
                      ></template
                    ><span>{{ $t("firstcall-action-attach") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('117')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        v-on="on"
                        color="red"
                        @click.stop="detachAssignment(item)"
                        ><v-icon>mdi-pipe-disconnected</v-icon></v-btn
                      ></template
                    ><span>{{ $t("firstcall-action-detach") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('130')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="!item.path"
                        x-small
                        text
                        icon
                        v-on="on"
                        color="purple"
                        @click.stop="playCall(item)"
                        ><v-icon>mdi-phone</v-icon></v-btn
                      ></template
                    ><span>{{ $t("firstcall-action-listen") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('131')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="!item.path"
                        x-small
                        text
                        icon
                        v-on="on"
                        color="success"
                        @click.stop="downloadCall(item)"
                        ><v-icon>mdi-arrow-down</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("firstcall-action-download")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('58')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="showPdf(item)"
                        v-on="on"
                        ><v-icon>mdi-file-pdf-outline</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-pdf") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('61')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="gray"
                        @click.stop="editGuest(item)"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-edit") }}</span></v-tooltip
                  >
                </div>
              </template>

              <template v-slot:item.pagato="{ item }">
                <v-icon v-if="item.pagato == 'Y'" color="green"
                  >mdi-check</v-icon
                >

                <v-icon v-if="item.pagato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.anagrafica_completato="{ item }">
                <v-icon
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      !$store.state.hasRight('88')
                  "
                  color="green"
                  >mdi-check</v-icon
                >

                <v-tooltip
                  v-if="
                    item.anagrafica_completato == 'Y' &&
                      $store.state.hasRight('88')
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      v-on="on"
                      @click.stop="resetPayment(item)"
                      ><v-icon>mdi-check</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("firstcall-tbl-wizard-reset")
                  }}</span></v-tooltip
                >

                <v-icon v-if="item.anagrafica_completato != 'Y'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
              </template>

              <template v-slot:item.payment_link="{ item }">
                <span v-html="buildPaymentLink(item)" />
                <span class="ml-2"
                  ><v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        v-on="on"
                        text
                        icon
                        @click.stop="copyLink(item)"
                        ><v-icon color="gray">mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{ $t("guests-action-copy-link") }}</span></v-tooltip
                  >
                </span>
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DateTime as LuxonDateTime } from "luxon";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import messengerManager from "../apis/messenger";
import guestsManager from "../apis/guests";
import usersManager from "../apis/users";
import localStorageService from "../services/localStorageService";
import config from "../config";
import staticData from "../apis/static";
import firstCall from "../apis/firstcall";
import AudioPlayer from "../components/firstcall/AudioPlayer";
import OutcomesResume from "../components/firstcall/OutcomesResume";
import GuestEditor from "../components/backend/GuestEditor";
import ChooseFirstCallOperator from "../components/backend/ChooseFirstCallOperator";
import messageBox from "../components/MessageBox";
import helpers from "../services/helpers";
import moment from "moment";
import duration from "moment-duration-format";
import axios from "axios";

duration(moment);

export default {
  data() {
    return {
      fencing_date: LuxonDateTime.local().toISO(),
      skip_fencing_watch: true,
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      guests: [],
      fetchingGuests: false,
      totalGuests: 0,
      outcomes: [],
      perPage: staticData.defaultPerPage,
      ftl_schedulers: [],
      flt_radios: [],

      options: {
        sortBy: ["time_elapsed"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      statistics: {
        payedGuests: "...",
        totalGuests: "...",

        queuedGuests: "...",
        postDayGuests: "...",
        recoveryGuests: "...",
        lastResortGuests: "...",
        inProgressCalls: "...",
        inProgressPostDayCalls: "...",
        inProgressRecoveryCalls: "...",
        inProgressLastResortCalls: "...",
      },

      filter: null,

      filters: {
        group: null,
        scheduler: null,
        operator: null,
        radio: null,
        date_type: 3,
        outcome: null,
        filterByPeriod: 11,
      },

      date_types: [
        { id: 0, name: this.$t("firstcall-filter-date-outcome") },
        { id: 1, name: this.$t("firstcall-filter-date-interview") },
        { id: 2, name: this.$t("firstcall-filter-date-link") },
        { id: 3, name: this.$t("firstcall-filter-date-assignment") },
      ],

      isLoadingOperator: false,

      operator: null,
      operators: [],
      searchOperatorInput: null,

      groups: firstCall.groups,

      headers: [],
      users: [],
      flt_users: {},
      flt_outcomes: [],
      fetchingData: false,
      fetchingOutcomes: true,
      audioPlayerDialog: false,
      outcomesResumeDialog: false,
      guestEditorDialog: false,

      date_start_modal: false,
      date_end_modal: false,

      date_fencing: null,
    };
  },

  name: "FirstcallManage",

  components: {
    messageBox,
    AudioPlayer,
    OutcomesResume,
    GuestEditor,
    Datetime,
    ChooseFirstCallOperator,
  },

  destroyed() {},

  mounted() {
    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];
    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers,
    ];

    this.headers = [
      {
        text: this.$t("firstcall-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("firstcall-tbl-name"),
        align: "left",
        sortable: true,
        value: "nome_intervistato_1",
      },
      {
        text: this.$t("firstcall-tbl-phone"),
        align: "left",
        sortable: true,
        value: "telefono",
      },
      {
        text: this.$t("firstcall-tbl-scheduler"),
        align: "left",
        sortable: true,
        value: "nome_palinsesto",
      },

      {
        text: this.$t("firstcall-tbl-operator"),
        align: "left",
        sortable: true,
        value: "operator",
      },
      {
        text: this.$t("firstcall-tbl-assignment-date"),
        align: "left",
        sortable: true,
        value: "assignment_day",
      },
      {
        text: this.$t("firstcall-tbl-link"),
        align: "left",
        sortable: false,
        value: "payment_link",
      },
      {
        text: this.$t("firstcall-tbl-link-time-elapsed"),
        align: "center",
        sortable: true,
        value: "time_elapsed",
      },
      {
        text: this.$t("firstcall-tbl-wa"),
        align: "center",
        sortable: false,
        value: "send_link_wa",
      },
      {
        text: this.$t("firstcall-tbl-wizard-completed"),
        align: "center",
        sortable: true,
        value: "anagrafica_completato",
      },
      {
        text: this.$t("firstcall-tbl-payment-completed"),
        align: "center",
        sortable: true,
        value: "pagato",
      },

      {
        text: this.$t("firstcall-tbl-outcome-counts"),
        align: "center",
        sortable: true,
        value: "outcomes_count",
      },
      {
        text: this.$t("firstcall-tbl-outcome"),
        align: "center",
        sortable: true,
        value: "last_outcome",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];

    this.fetchingData = true;
    this.fetchUsers()
      .then((res) => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map((x) => {
          this.flt_users[x.id] = x;
        });
        this.fetchOutcomes()
          .then((res) => {
            this.outcomes = res.outcomes;
            this.flt_outcomes = [
              { id: null, name: this.$t("gbl-all-male") },
              ...this.outcomes,
            ];
            this.fetchDataFencing()
              .then((res) => {
                this.fetchingData = false;
                this.date_fencing = res;
                if (this.date_fencing) {
                  this.skip_fencing_watch = true;
                  this.fencing_date = LuxonDateTime.fromSeconds(
                    parseInt(res)
                  ).toISO();
                } else {
                  this.skip_fencing_watch = true;
                  this.fencing_date = LuxonDateTime.local().toISO();
                }
              })
              .catch((err) => {
                this.fetchingData = false;
                console.log(err);
              });
          })
          .catch((err) => {
            this.fetchingData = false;
            console.log(err);
          });
      })
      .catch((err) => {
        this.fetchingData = false;
        console.log(err);
      });
  },

  watch: {
    options: {
      handler() {
        this.fetchGuests();
      },
      deep: true,
    },

    filters: {
      handler() {
        this.fetchGuests();
      },
      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true],
        };
      },
    },

    fencing_date: {
      handler() {
        if (!this.skip_fencing_watch) {
          this.fencingDateTimeConfigured(this.fencing_date);
        }
      },
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      },
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  methods: {
    downloadWAReceipt(item) {
      console.log(item)
      usersManager
        .keepAlive()
        .then(() => {
          messengerManager
            .downloadWAReceipt(
              item.ID,
              this.$store.state.user.locale,
              3
            )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPeriodFilters: () => {
      return staticData.filtersPeriods();
    },

    printDateFencingWarning() {
      let time = LuxonDateTime.fromISO(this.fencing_date);
      return this.$t("firstcall-datafencing-warning").replace(
        "{1}",
        time.toLocaleString(LuxonDateTime.DATETIME_SHORT)
      );
    },

    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        firstCall
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    editGuest(item) {
      this.$refs.guestEditor.setModalGuest(item, true);
      this.guestEditorDialog = true;
    },

    onGuestConfigured(res) {
      if (res.ref.esito != res.item.esito && res.item.esito == 2) {
        res.item.set_interview_date_now = true;
      }
      guestsManager
        .editGuest(res.item)
        .then(() => {
          this.fetchGuests();
          this.guestEditorDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.guestEditorDialog = false;
        });
    },

    showPdf(item) {
      usersManager
        .keepAlive()
        .then(() => {
          guestsManager
            .downloadPDF(
              item.id_ospite ? item.id_ospite : item.ID,
              this.$store.state.user.locale,
              false,
              item.anagrafica_completato == "Y" ? true : false
            )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    outcomesResume(item) {
      this.$refs.outcomesResumeDialog.setItem(item.ID);
      this.outcomesResumeDialog = true;
    },

    sendWA(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+39") != 0) phoneNumber = "+39" + phoneNumber;

      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "https://api.whatsapp.com/send?phone=" + encodeURIComponent(phoneNumber)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    downloadCall(item) {
      let id = item.ID;
      const token = localStorageService.getAccessToken();
      let tokens = item.path.split("/");
      let filename = tokens.pop();
      const url =
        config.apiEndPoint +
        "/firstcall/download/" +
        id +
        "?token=" +
        encodeURIComponent(token);

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    playCall(item) {
      this.$refs.audioPlayerDialog.setPodcast(item);
      this.audioPlayerDialog = true;
    },

    resetPayment(item) {
      this.$refs.msgBox
        .show(this.$t("reset-payment-warning"), this.$t("reset-payment-title"))
        .then(() => {
          firstCall
            .resetPayment(item)
            .then((res) => {
              if (res.data.responseData) item.anagrafica_completato = "N";
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    detachAssignment(item) {
      this.$refs.msgBox
        .show(
          this.$t("firstcall-detach-warning"),
          this.$t("firstcall-detach-title")
        )
        .then(() => {
          firstCall
            .detachAssignment(item.assignment_id)
            .then(() => {
              this.fetchGuests();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    onOperatorChoosen(payload) {
      firstCall
        .reassignAssignment(payload.item, payload.selection)
        .then(() => {
          this.$refs.ChooseFirstCallOperator.closeDialog();
          this.fetchGuests();
        })
        .catch((err) => {
          this.$refs.ChooseFirstCallOperator.closeDialog();
          console.log(err);
        });
    },

    reattachAssignment(item) {
      this.$refs.ChooseFirstCallOperator.openModal(item.assignment_id);
    },

    getLinkTimeStyle(item) {
      const date = moment(item.data_invio_link_pagamento);
      if (date.dayOfYear() != moment().dayOfYear()) return "color:red";
      else return "";
    },

    formatElapsed(time) {
      const moment_time = moment.duration(time, "seconds");
      return moment_time.format("d[d] hh:mm:ss");
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    copyLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      navigator.clipboard.writeText(link).then(
        () => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied"),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        },
        (err) => {
          this.$refs.msgBox
            .show(
              this.$t("gbl-link-copied-error" + ":" + err),
              this.$t("gbl-operation-title"),
              true
            )
            .then(() => {})
            .catch(() => {});
        }
      );
    },

    buildPaymentLink(item) {
      let link = helpers.buildPaymentLinkURL(item);
      let shortLink = helpers.buildPaymentShortLinkURL(item);
      return "<a target='_blank' href='" + link + "'>" + shortLink + "</a>";
    },

    fetchOutcomes() {
      this.fetchingOutcomes = true;
      return new Promise((resolve, reject) => {
        firstCall
          .fetchOutcomes()
          .then((result) => {
            this.fetchingOutcomes = false;
            this.outcomes = result.outcomes;
            resolve(result);
          })
          .catch((err) => {
            this.fetchingOutcomes = false;
            reject(err);
          });
      });
    },

    fetchDataFencing() {
      return new Promise((resolve, reject) => {
        firstCall
          .fetchDataFencing()
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    disableDateFencing() {
      return new Promise((resolve, reject) => {
        firstCall
          .setDataFencing(null)
          .then(() => {
            this.date_fencing = null;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fencingDateTimeConfigured(event) {
      let time = LuxonDateTime.fromISO(event).toUTC();
      firstCall
        .setDataFencing(time.toSeconds())
        .then((res) => {
          this.date_fencing = res.timestamp;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setDataFencing() {
      this.skip_fencing_watch = false;
      this.$refs.fencingDateTime.isOpen = true;
    },

    setupElapsedTimer() {
      clearTimeout(this._updateTimestamps);
      this._updateTimestamps = setTimeout(() => {
        for (let n = 0; n < this.guests.length; n++) {
          let guest = this.guests[n];
          if (guest.outcomes_count < 1) guest.time_elapsed++;
        }

        this.setupElapsedTimer();
      }, 1000);
    },

    fetchGuests() {
      clearTimeout(this._updateTimestamps);
      this.fetchingGuests = true;
      this.guests = [];
      this.totalGuests = 0;
      this.statistics = {
        queuedGuests: "...",
        postDayGuests: "...",
        recoveryGuests: "...",
        lastResortGuests: "...",
        inProgressCalls: "...",
        inProgressPostDayCalls: "...",
        inProgressRecoveryCalls: "...",
        inProgressLastResortCalls: "...",
      };

      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        firstCall
          .fetchGuests(ctx)
          .then((result) => {
            this.guests = result.items;
            this.totalGuests = result.totalCount;
            this.statistics = result.statistics;
            this.fetchingGuests = false;
            resolve();
            this.setupElapsedTimer();
          })

          .catch((err) => {
            this.fetchingGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>

<style>
.date-invisible {
  display: none;
}
</style>
